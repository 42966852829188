import React, { Suspense, useState } from 'react';
import parse, { domToReact } from 'html-react-parser';
import Link from 'next/link';
import TopSellerProducts from '../TopSellerProducts';
import { isMobile } from 'react-device-detect';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/solid';
import SeoText from '../SeoText';
import { Tab, Disclosure } from '@headlessui/react';
import Slider from 'react-slick';
import PromoCodeBtn from '../PromoCodeBtn';
import Timer from '../Timer';

const BannerSlider = React.lazy(() => import('../BannerSlider'));

const RichContent = (props) => {
    const { html } = props;
    const [readmorebtnState, setReadmorebtnState] = useState('closed');
    const [selectedIndex, setSelectedIndex] = useState(0);
    const options = {
        replace: function (domNode) {
            if (
                domNode.type == 'tag' &&
                domNode.name === 'redirect' &&
                domNode.attribs.url
            ) {
                return (
                    <Suspense fallback={null}>
                        {(window.location.href = domNode.attribs.url)}
                    </Suspense>
                );
            }

            if (
                domNode.type == 'tag' &&
                domNode.name === 'a' &&
                domNode.attribs.href &&
                domNode.attribs.target != '_blank' &&
                domNode.attribs.type != 'anchor'
            ) {
                return (
                    <Link
                        id={domNode.attribs.id}
                        href={domNode.attribs.href}
                        passHref
                    >
                        <a className={domNode.attribs.class}>
                            {domToReact(domNode.children, options)}
                        </a>
                    </Link>
                );
            }

            if (
                domNode.type == 'tag' &&
                domNode.name === 'bannerslider' &&
                domNode.attribs.id
            ) {
                return (
                    <Suspense fallback={null}>
                        <BannerSlider
                            id={Number(domNode.attribs.id)}
                            aspectRatio={domNode.attribs.aspectratio}
                            aspectRatioMobile={
                                domNode.attribs.aspectratiomobile
                            }
                        />
                    </Suspense>
                );
            }

            if (domNode.type == 'tag' && domNode.name === 'carousel') {
                const dotsCativeClass =
                    domNode.attribs.dots == undefined
                        ? 'activeDots'
                        : domNode.attribs.dots == 'false'
                        ? ''
                        : 'activeDots';
                const sliderSettings = {
                    dots:
                        domNode.attribs.dots == undefined
                            ? true
                            : domNode.attribs.dots == 'false'
                            ? false
                            : true,
                    arrows:
                        domNode.attribs.arrows == undefined
                            ? false
                            : domNode.attribs.arrows == 'true'
                            ? true
                            : false,
                    speed: 500,
                    slidesToShow:
                        domNode.attribs.maxitemstoshow == undefined
                            ? 4
                            : Number(domNode.attribs.maxitemstoshow),
                    slidesToScroll:
                        domNode.attribs.maxitemstoshow == undefined
                            ? 4
                            : Number(domNode.attribs.maxitemstoshow),
                    infinite: false,
                    className: dotsCativeClass,
                    draggable: false,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                dots:
                                    domNode.attribs.mobiledots == 'true'
                                        ? true
                                        : false,
                                arrows:
                                    domNode.attribs.mobiledots == 'true'
                                        ? false
                                        : true,
                                infinite: true,
                                centerMode: false,
                                speed: 500,
                                slidesToShow: 3,
                                slidesToScroll: 3,
                                className:
                                    domNode.attribs.mobiledots == 'true'
                                        ? 'activeDots carousel'
                                        : 'carousel',
                                draggable: true
                            }
                        },
                        {
                            breakpoint: 640,
                            settings: {
                                dots:
                                    domNode.attribs.mobiledots == 'true'
                                        ? true
                                        : false,
                                arrows:
                                    domNode.attribs.mobiledots == 'true'
                                        ? false
                                        : true,
                                infinite: true,
                                centerMode: true,
                                speed: 500,
                                slidesToShow: 2,
                                slidesToScroll: 2,
                                className:
                                    domNode.attribs.mobiledots == 'true'
                                        ? 'activeDots carousel'
                                        : 'carousel',
                                draggable: true
                            }
                        },
                        {
                            breakpoint: 420,
                            settings: {
                                dots:
                                    domNode.attribs.mobiledots == 'true'
                                        ? true
                                        : false,
                                arrows:
                                    domNode.attribs.mobiledots == 'true'
                                        ? false
                                        : true,
                                infinite: true,
                                centerMode: true,
                                speed: 500,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                className:
                                    domNode.attribs.mobiledots == 'true'
                                        ? 'activeDots carousel'
                                        : 'carousel',
                                draggable: true
                            }
                        }
                    ]
                };

                return (
                    <div className="content-container">
                        <Suspense fallback={null}>
                            <Slider {...sliderSettings} className="carousel">
                                {domToReact(domNode.children, options)}
                            </Slider>
                        </Suspense>
                    </div>
                );
            }

            if (
                domNode.type == 'tag' &&
                domNode.name === 'topsellerproducts' &&
                domNode.attribs.id
            ) {
                return (
                    <Suspense fallback={null}>
                        <TopSellerProducts
                            id={domNode.attribs.id}
                            mobileColumns={
                                !domNode.attribs.mobilecolumns
                                    ? domNode.attribs.mobilecolumns
                                    : 2
                            }
                            pagination={
                                domNode.attribs.pagination == 'true'
                                    ? domNode.attribs.pagination
                                    : null
                            }
                            noHeadline={true}
                            pright={domNode.attribs.pright}
                            pleft={domNode.attribs.pleft}
                        />
                    </Suspense>
                );
            }

            if (domNode.type == 'tag' && domNode.name === 'ceocmscontent') {
                return (
                    <SeoText>{domToReact(domNode.children, options)}</SeoText>
                );
            }

            if (domNode.type == 'tag' && domNode.name === 'tabgroup') {
                return (
                    <Tab.Group
                        as="div"
                        selectedIndex={selectedIndex}
                        onChange={setSelectedIndex}
                    >
                        {domToReact(domNode.children, options)}
                    </Tab.Group>
                );
            }
            if (domNode.type == 'tag' && domNode.name === 'tablist') {
                return (
                    <Tab.List as="div" className={domNode.attribs.class}>
                        {domToReact(domNode.children, options)}
                    </Tab.List>
                );
            }

            if (domNode.type == 'tag' && domNode.name === 'tab') {
                return (
                    <Tab as="button" className={domNode.attribs.class}>
                        {domToReact(domNode.children, options)}
                    </Tab>
                );
            }

            if (domNode.type == 'tag' && domNode.name === 'tabpanels') {
                return (
                    <Tab.Panels>
                        {domToReact(domNode.children, options)}
                    </Tab.Panels>
                );
            }
            if (domNode.type == 'tag' && domNode.name === 'tabpanel') {
                return (
                    <Tab.Panel>
                        {domToReact(domNode.children, options)}
                    </Tab.Panel>
                );
            }
            if (domNode.type == 'tag' && domNode.name === 'disclosure') {
                return (
                    <Disclosure
                        defaultOpen={
                            domNode.attribs.defaultopen == 'true' ? true : false
                        }
                    >
                        {({ open }) => domToReact(domNode.children, options)}
                    </Disclosure>
                );
            }

            if (domNode.type == 'tag' && domNode.name === 'disclosurebutton') {
                return (
                    <Disclosure.Button
                        as="button"
                        className={domNode.attribs.class}
                    >
                        {domToReact(domNode.children, options)}
                    </Disclosure.Button>
                );
            }

            if (domNode.type == 'tag' && domNode.name === 'disclosurepanel') {
                return (
                    <Disclosure.Panel
                        as="div"
                        className={domNode.attribs.class}
                    >
                        {domToReact(domNode.children, options)}
                    </Disclosure.Panel>
                );
            }
            if (
                domNode.type == 'tag' &&
                domNode.name === 'promocodebtn' &&
                domNode.attribs.code &&
                domNode.attribs.id
            ) {
                return (
                    <PromoCodeBtn
                        code={domNode.attribs.code}
                        id={domNode.attribs.id}
                    />
                );
            }

            if (
                domNode.type == 'tag' &&
                domNode.name === 'countdown' &&
                domNode.attribs.datestring
            ) {
                return (
                    <Timer
                        dateString={domNode.attribs.datestring}
                        finalMessage={domNode.attribs.finalmessage}
                    />
                );
            }
        }
    };

    // Even if empty, render a div with no content, for styling purposes.
    if (!html) {
        return <div />;
    }

    const unescapeHTML = (escapedHTML) => {
        return escapedHTML
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&amp;/g, '&');
    };
    return <div id="richContentBody">{parse(unescapeHTML(html), options)}</div>;
};
export default RichContent;
