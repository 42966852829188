import Image from 'next/image';
import LoadingContent from '../LoadingContent';

export const sidebarLoadingTemplate = (
    <div className="mt-4 border-t border-gray-200 hidden lg:block ">
        <LoadingContent tailWindCssString="w-full h-8 mt-2" />
        <LoadingContent tailWindCssString="w-full h-8 mt-2" />
        <LoadingContent tailWindCssString="w-full h-8 mt-2" />
        <LoadingContent tailWindCssString="w-full h-8 mt-2" />
        <LoadingContent tailWindCssString="w-full h-8 mt-2" />
        <LoadingContent tailWindCssString="w-full h-8 mt-2" />
        <LoadingContent tailWindCssString="w-full h-8 mt-2" />
    </div>
);

export const categoryLoadingContentTemplate = (
    <>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
        <div className="block">
            <LoadingContent tailWindCssString="w-full aspect-w-1 aspect-h-1" />
            <div className="lg:min-h-[136px] md:min-h-[131px] sm:min-h-[116px] xs:min-h-[116px] min-h-[116px]">
                <div className="flex items-start mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 sm:h-2 lg:h-2 xs:h-2 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                    <LoadingContent tailWindCssString="w-1/2 sm:h-2 lg:h-2 xs:h-2 h-2 mt-2" />
                </div>
                <div className="flex items-end mb-4 flex-col my-2">
                    <LoadingContent tailWindCssString="w-1/6 h-2 mt-1" />
                    <LoadingContent tailWindCssString="w-2/6 h-2 mt-2" />
                </div>
            </div>
        </div>
    </>
);

export const homepageProductsLoadingTemplate = (
    <>
        <div className="flex -ml-3 sm:-mx-3 overflow-x-hidden">
            <div className="flex-[0_0_auto] w-2/3 px-3 sm:w-1/2 md:flex-auto">
                <LoadingContent tailWindCssString="">
                    <div className="w-full aspect-w-1 aspect-h-1" />
                    <div className="pb-4">
                        <div className="h-[74px] w-full" />
                        <div className="h-[50.25px] w-full" />
                    </div>
                </LoadingContent>
            </div>
            <div className="flex-[0_0_auto] w-2/3 px-3 sm:w-1/2 md:flex-auto">
                <LoadingContent tailWindCssString="">
                    <div className="w-full aspect-w-1 aspect-h-1" />
                    <div className="pb-4">
                        <div className="h-[74px] w-full" />
                        <div className="h-[50.25px] w-full" />
                    </div>
                </LoadingContent>
            </div>
            <div className="hidden px-3 w-2/3 sm:w-1/2 md:block">
                <LoadingContent tailWindCssString="">
                    <div className="w-full aspect-w-1 aspect-h-1" />
                    <div className="pb-4">
                        <div className="h-[74px] w-full" />
                        <div className="h-[50.25px] w-full" />
                    </div>
                </LoadingContent>
            </div>
            <div className="hidden px-3 w-2/3 sm:w-1/2 lg:block">
                <LoadingContent tailWindCssString="">
                    <div className="w-full aspect-w-1 aspect-h-1" />
                    <div className="pb-4">
                        <div className="h-[74px] w-full" />
                        <div className="h-[50.25px] w-full" />
                    </div>
                </LoadingContent>
            </div>
        </div>
        <LoadingContent tailWindCssString="h-[3px] w-full mt-6 md:hidden" />
    </>
);

export const itemInCartLoadingContentTemplate = (
    <>
        <div className="flex flex-wrap justify-start mt-8">
            <LoadingContent tailWindCssString="block w-24 h-24 mr-2" />
            <div className="w-32 mb-8">
                <LoadingContent tailWindCssString="w-full h-4 mb-2" />
                <LoadingContent tailWindCssString="w-1/2 h-4 mb-8" />
                <div className="flex flex-wrap justify-start">
                    <LoadingContent tailWindCssString="w-1/2 h-6 mb-2 mr-2" />
                    <LoadingContent tailWindCssString="w-6 h-6" />
                </div>
            </div>
        </div>
    </>
);

export const wishlistLoadingContentTemplate = () => (
    <>
        <div className="sm:hidden">
            <LoadingContent tailWindCssString="w-full h-80 mb-2" />
            <LoadingContent tailWindCssString="w-full h-80 mb-2" />
            <LoadingContent tailWindCssString="w-full h-80 mb-2" />
            <LoadingContent tailWindCssString="w-full h-80 mb-2" />
        </div>
        <div className="hidden sm:block lg:hidden">
            <LoadingContent tailWindCssString="w-full h-44 mb-2" />
            <LoadingContent tailWindCssString="w-full h-44 mb-2" />
            <LoadingContent tailWindCssString="w-full h-44 mb-2" />
            <LoadingContent tailWindCssString="w-full h-44 mb-2" />
        </div>
        <div className="hidden lg:block">
            <LoadingContent tailWindCssString="w-full h-11 mb-2" />
            <LoadingContent tailWindCssString="w-full h-28 mb-2" />
            <LoadingContent tailWindCssString="w-full h-28 mb-2" />
            <LoadingContent tailWindCssString="w-full h-28 mb-2" />
            <LoadingContent tailWindCssString="w-full h-28 mb-2" />
            <LoadingContent tailWindCssString="w-full h-28 mb-2" />
            <LoadingContent tailWindCssString="w-full h-28 mb-2" />
            <LoadingContent tailWindCssString="w-full h-28 mb-2" />
            <LoadingContent tailWindCssString="w-full h-28 mb-2" />
            <LoadingContent tailWindCssString="w-full h-28 mb-2" />
        </div>
    </>
);

export const filtersBoxLoadingContentTemplate = (
    <LoadingContent tailWindCssString="bg-gray-200 row-span-3 hidden lg:block h-full w-full" />
);

export const bannerSliderLoadingContentTemplate = (
    aspectRatio?: string,
    additionaClass?: string
) => (
    <LoadingContent
        tailWindCssString={
            'bg-gray-200 row-span-3 block w-full ' + additionaClass
        }
        aspectRatio={aspectRatio}
    />
);

export const browserNavigationBarTemplate = (
    <div className="hidden lg:block py-[12px]">
        <LoadingContent tailWindCssString={'h-5 w-full'} />
    </div>
);

export const variantsLoadingTemplate = (
    <div className="flex flex-wrap justify-start mt-8">
        <LoadingContent tailWindCssString="block w-full h-12" />
    </div>
);

export const variantsColorsLoadingTemplate = (
    <div className="flex flex-wrap justify-start mt-8">
        <LoadingContent tailWindCssString="block w-full h-12 w-12 mr-6 rounded-full" />
    </div>
);

export const filterHeadlineTemplate = (
    <div className="flex flex-wrap justify-start mb-8">
        <LoadingContent tailWindCssString="block w-full h-[69px] lg:h-[105px]" />
    </div>
);

export const autocompleteWindowTemplate = (
    <div className="flex flex-col p-5">
        <LoadingContent tailWindCssString="h-[32px] w-[200px] mb-5" />
        <div className="grid grid-cols-5 gap-8">
            <LoadingContent tailWindCssString="h-fit">
                <Image
                    height={100}
                    width={150}
                    src={
                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />
                <div className="h-[39px] w-full" />
            </LoadingContent>
            <LoadingContent tailWindCssString="h-fit">
                <Image
                    height={100}
                    width={150}
                    src={
                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />
                <div className="h-[39px] w-full" />
            </LoadingContent>
            <LoadingContent tailWindCssString="h-fit">
                <Image
                    height={100}
                    width={150}
                    src={
                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />
                <div className="h-[39px] w-full" />
            </LoadingContent>
            <LoadingContent tailWindCssString="h-fit">
                <Image
                    height={100}
                    width={150}
                    src={
                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />
                <div className="h-[39px] w-full" />
            </LoadingContent>
            <LoadingContent tailWindCssString="h-fit">
                <Image
                    height={100}
                    width={150}
                    src={
                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />
                <div className="h-[39px] w-full" />
            </LoadingContent>
            <LoadingContent tailWindCssString="h-fit">
                <Image
                    height={100}
                    width={150}
                    src={
                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />
                <div className="h-[39px] w-full" />
            </LoadingContent>
            <LoadingContent tailWindCssString="h-fit">
                <Image
                    height={100}
                    width={150}
                    src={
                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />
                <div className="h-[39px] w-full" />
            </LoadingContent>
            <LoadingContent tailWindCssString="h-fit">
                <Image
                    height={100}
                    width={150}
                    src={
                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />
                <div className="h-[39px] w-full" />
            </LoadingContent>
            <LoadingContent tailWindCssString="h-fit">
                <Image
                    height={100}
                    width={150}
                    src={
                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />
                <div className="h-[39px] w-full" />
            </LoadingContent>
            <LoadingContent tailWindCssString="h-fit">
                <Image
                    height={100}
                    width={150}
                    src={
                        '/pub/media/catalog/product/placeholder/default/placeholder_2.jpg'
                    }
                    alt=""
                    className="opacity-0"
                />
                <div className="h-[39px] w-full" />
            </LoadingContent>
        </div>
    </div>
);
