const convertToWeeks = (days) => {
    const dayLabel = days == 1 ? ' Tag' : ' Tagen';
    if (days < 7) {
        return Math.ceil(days) + dayLabel;
    } else {
        return Math.ceil(days / 5) + ' Wochen';
    }
};

export default convertToWeeks;
