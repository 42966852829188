import Button from '../Button';
import Overlay from '../Overlay';

type buttonType = {
    label: string;
    action: Function;
    disabledStyles?: boolean;
};
interface PopupBoxProps {
    buttons?: buttonType[];
    headline?: string;
    description?: string;
    isBoxOpened: boolean;
    setIsBoxOpened: React.Dispatch<React.SetStateAction<boolean>>;
    children?: JSX.Element;
    closeByOverlayDisabled?: boolean;
    className?: string;
    showCloseBtn?: boolean;
    actionOnClose?: Function;
    isFullHeight?: boolean;
}

const PopupBox = (props: PopupBoxProps) => {
    const {
        buttons,
        headline,
        description,
        isBoxOpened,
        setIsBoxOpened,
        closeByOverlayDisabled,
        className,
        showCloseBtn,
        actionOnClose,
        isFullHeight
    } = props;

    return isBoxOpened ? (
        <Overlay
            onClick={closeByOverlayDisabled ? () => {} : setIsBoxOpened}
            isFullHeight={isFullHeight}
            mobileBottomMode={true}
        >
            <div
                className={`w-full flex flex-col max-w-sm mx-auto p-6 border border-skin-primary bg-white shadow ${className}`}
            >
                {showCloseBtn ? (
                    <button
                        type="button"
                        className={
                            headline
                                ? 'absolute right-3 top-4 xs:right-2'
                                : 'absolute right-3 top-2 xs:top-4 xs:right-2'
                        }
                        onClick={() => {
                            actionOnClose ? actionOnClose() : null;
                            setIsBoxOpened(false);
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="27"
                            height="27"
                            viewBox="0 0 33.491 33.491"
                        >
                            <g
                                id="Group_781"
                                data-name="Group 781"
                                transform="translate(-3.375 -3.375)"
                            >
                                <path
                                    id="Path_1255"
                                    data-name="Path 1255"
                                    d="M24.88,23.061l-4.243-4.243,4.243-4.243a1.287,1.287,0,1,0-1.819-1.819L18.818,17l-4.243-4.243a1.287,1.287,0,1,0-1.819,1.819L17,18.819l-4.243,4.243a1.244,1.244,0,0,0,0,1.819,1.278,1.278,0,0,0,1.819,0l4.243-4.243,4.243,4.243a1.293,1.293,0,0,0,1.819,0A1.278,1.278,0,0,0,24.88,23.061Z"
                                    transform="translate(1.305 1.302)"
                                />
                                <path
                                    id="Path_1256"
                                    data-name="Path 1256"
                                    d="M20.121,5.629A14.486,14.486,0,1,1,9.872,9.872,14.4,14.4,0,0,1,20.121,5.629m0-2.254A16.746,16.746,0,1,0,36.866,20.121,16.743,16.743,0,0,0,20.121,3.375Z"
                                />
                            </g>
                        </svg>
                    </button>
                ) : null}
                {headline ? (
                    <h3 className="text-left mb-4">{headline}</h3>
                ) : null}
                {props?.children}
                {description ? (
                    <div className={'mb-4'}>
                        <p>{description}</p>
                    </div>
                ) : null}
                {buttons && buttons.length > 0 ? (
                    <div className="flex justify-center space-x-4">
                        {buttons.map((button) => {
                            const disabledBtnStyles = button.disabledStyles
                                ? ' bg-skin-primary'
                                : ' bg-skin-blue-bg transition-all duration-500 ease-in-out hover:bg-skin-primary-hover';

                            return (
                                <>
                                    <Button
                                        key={button.label}
                                        onClick={(e) => {
                                            setIsBoxOpened(false);
                                            button.action(e);
                                        }}
                                        className={`text-base font-bold px-10 border border-transparent ${disabledBtnStyles}`}
                                    >
                                        {button.label}
                                    </Button>
                                </>
                            );
                        })}
                    </div>
                ) : null}
            </div>
        </Overlay>
    ) : null;
};

export default PopupBox;
