import { useEffect } from 'react';
import { bool } from 'prop-types';
import { withCookies } from 'react-cookie';
import SIGN_OUT from '../../queries/signOut.graphql';
import { useSignOut } from '../../talons/SignOut/useSignOut';
import { connect } from 'react-redux';
import { compose } from 'redux';

const SessionMonitoring = (props) => {
    const { cookies, isSignedIn } = props;
    const talons = useSignOut({
        signOutMutation: SIGN_OUT
    });

    const { handledSignOut } = talons;

    setInterval(() => {
        if (cookies.get('authToken') == undefined && isSignedIn) {
            handledSignOut();
        }
    }, 30000);

    useEffect(() => {
        if (cookies.get('authToken') == undefined && isSignedIn) {
            handledSignOut();
        }
        if (cookies.get('clearStorage') == undefined) {
            cookies.set('clearStorage', false, {
                path: '/',
                maxAge: 60 * 60 * 24 * 5
            });
        }
    }, []);

    return '';
};

const mapStateToProps = ({ user: { isSignedIn } }) => {
    return {
        isSignedIn
    };
};

export default compose(
    withCookies,
    connect(mapStateToProps)
)(SessionMonitoring);

SessionMonitoring.propTypes = {
    isSignedIn: bool
};
