export const createCart = (payload) => {
    return {
        type: 'CREATE_CART',
        payload
    };
};
export const createCartDetails = (payload) => {
    return {
        type: 'CREATE_CART_DETAILS',
        payload
    };
};
export const createCartState = (payload) => {
    return {
        type: 'CREATE_CART_STATE',
        payload
    };
};

export const resetCartState = () => {
    return {
        type: 'RESET_CART_STATE'
    };
};

export const isUpdatingItemInCart = payload => {
    return {
        type: 'IS_UPDATING_ITEM_IN_CART',
        payload
    }
}


export const isAddingItemInCart = payload => {
    return {
        type: 'IS_ADDING_ITEM_IN_CART',
        payload
    }
}


export const isRemovingItemInCart = payload => {
    return {
        type: 'IS_REMOVING_ITEM_IN_CART',
        payload
    }
}