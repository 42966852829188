import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import SearchBarDesktop from '../SearchBarDesktop/searchBarDesktop';
import SearchBarMobile from '../SearchBarMobile/searchBarMobile';
import TopBanner from '../TopBanner';
import UserIcon from '../../images/user_icon.svg';
import UserIconMobile from '../../images/user_icon_mobile.svg';
import HeartIcon from '../../images/wishlist_icon.svg';
import CartIcon from '../../images/cart_icon.svg';
import HeartIconMobile from '../../images/wishlist_icon_mobile.svg';
import CartIconMobile from '../../images/cart_icon_mobile.svg';
import { SearchIcon, LogoutIcon, MenuIcon } from '@heroicons/react/outline';
import Logo from '../../images/logo.svg';
import { toggleDrawer } from '../../store/actions/app';
import ProductCount from './ProductCount';
import WishlistCount from './WishlistCount';
import { useSignOut } from '../../talons/SignOut/useSignOut';
import { connect } from 'react-redux';
import SIGN_OUT from '../../queries/signOut.graphql';
import MobileNavigation from './MobileNavigation';
import DesktopNavigation from './DesktopNavigation';
import { isMobile } from 'react-device-detect';
import LogoutIconMobile from '/images/logout.svg';
import { useRouter } from 'next/router';
import LoginPopup from './LoginPopup';

const Header = (props: NavigationPropTypes) => {
    const {
        categories,
        categoryUrlSuffix,
        toggleNavi,
        isSignedIn,
        drawer,
        loadingNavi
    } = props;

    const [loginPopupOpen, setLoginPopupOpen] = useState(false);

    const router = useRouter();
    const pathname = router.pathname;
    const talons = useSignOut({
        signOutMutation: SIGN_OUT
    });
    const mobileHeader = useRef<HTMLDivElement>(null);

    const { handledSignOut } = talons;

    const featuredCategories = [5, 6];
    const staticItems = {
        pages: []
    };

    const isOpen = drawer == 'navi';

    useEffect(() => {
        const addScrollShadow = () => {
            if (mobileHeader.current) {
                window.scrollY >= 10
                    ? (mobileHeader.current.style.boxShadow =
                          '0px 2px 2px rgba(0, 0, 0, 0.2)')
                    : (mobileHeader.current.style.boxShadow = 'none');
            }
        };

        window.addEventListener('scroll', addScrollShadow);

        return () => window.removeEventListener('scroll', addScrollShadow);
    }, []);

    return (
        <div className={pathname === '/checkout' ? 'hidden' : `bg-white`}>
            {/* Mobile menu */}

            <MobileNavigation
                isOpen={isOpen}
                categories={categories}
                featuredCategories={featuredCategories}
                staticItems={staticItems}
                toggleNavi={toggleNavi}
                categoryUrlSuffix={categoryUrlSuffix}
                isSignedIn={isSignedIn}
                handledSignOut={handledSignOut}
            />

            <header className="relative bg-white">
                <TopBanner />
                <div className="border-solid border-skin-primary border-b">
                    <div>
                        <div aria-label="Top" className="content-container">
                            {/* Mobile Links */}
                            <div
                                ref={mobileHeader}
                                className="flex justify-between align-center lg:hidden top-0 left-0 content-container fixed w-full z-30 bg-white !shadow-none border-b border-solid border-skin-muted"
                            >
                                <div className="flex justify-between items-center align-center lg:hidden">
                                    <button
                                        aria-label="Menü"
                                        type="button"
                                        className="p-2 pl-0 rounded-md text-gray-400 content-center -ml-1"
                                        onClick={() => toggleNavi()}
                                    >
                                        <MenuIcon
                                            className="w-8 h-8 text-[#191919]"
                                            aria-hidden="true"
                                        />
                                    </button>
                                    {/* Mobile logo */}
                                    <div className="block">
                                        <Link href="/" passHref>
                                            <a aria-label="Funkelhaus.de">
                                                <Logo
                                                    className={
                                                        'h-5 my-3 sm:h-6'
                                                    }
                                                />
                                            </a>
                                        </Link>
                                    </div>
                                    {/* End Mobile logo */}
                                </div>
                                <div className="flex justify-between align-center -mx-1">
                                    <Link href="/login">
                                        <span className="px-1 sm:pl-4 rounded-md text-gray-400 flex flex-col justify-center">
                                            <UserIconMobile
                                                className="mx-auto font-normal h-5"
                                                aria-hidden="true"
                                            />
                                        </span>
                                    </Link>
                                    {isSignedIn ? (
                                        <span className="px-1 sm:pl-4 rounded-md text-gray-400 flex flex-col justify-center">
                                            <LogoutIconMobile
                                                className="h-5 w-5"
                                                arian-hidden="true"
                                                onClick={handledSignOut}
                                            />
                                        </span>
                                    ) : null}
                                    <Link href="/wishlist">
                                        <span className="px-1 sm:pl-4 rounded-md text-gray-400 flex flex-col justify-center relative">
                                            <HeartIconMobile
                                                className="mx-auto font-normal h-5"
                                                aria-hidden="true"
                                            />
                                            <WishlistCount mobileView={true} />
                                        </span>
                                    </Link>
                                    <Link href="/cart">
                                        <a
                                            aria-label="Warenkorb"
                                            className="px-1 sm:pl-4 rounded-md text-gray-400 flex flex-col justify-center relative"
                                        >
                                            <CartIconMobile
                                                className="mx-auto font-normal h-5"
                                                aria-hidden="true"
                                            />
                                            <ProductCount mobileView={true} />
                                        </a>
                                    </Link>
                                </div>
                            </div>
                            <div className="lg:hidden mt-14">
                                <SearchBarMobile />
                            </div>
                            {/* End Mobile links */}

                            {/* Browser links */}
                            <div className="border-b border-gray-200 hidden lg:block">
                                <div className="flex items-center">
                                    {/* Browser logo */}
                                    <div className="ml-4 flex lg:ml-0 hidden lg:block">
                                        <Link href="/" passHref>
                                            <a aria-label="Funkelhaus.de">
                                                <Logo className="lg:h-8 my-5 cursor-pointer" />
                                            </a>
                                        </Link>
                                    </div>
                                    {/* End Browser logo */}
                                    <div className="sm:hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                                        <SearchBarDesktop />
                                    </div>
                                    <div className="ml-auto flex items-center">
                                        <div className=" flex flex-1 justify-start items-center">
                                            {!isSignedIn ? (
                                                !isMobile ? (
                                                    <div
                                                        className="relative"
                                                        onMouseEnter={() =>
                                                            setLoginPopupOpen(
                                                                true
                                                            )
                                                        }
                                                        onMouseLeave={() =>
                                                            setLoginPopupOpen(
                                                                false
                                                            )
                                                        }
                                                        onClick={() => {
                                                            isSignedIn
                                                                ? router.push(
                                                                      '/account'
                                                                  )
                                                                : router.push(
                                                                      '/login'
                                                                  );
                                                        }}
                                                    >
                                                        <span className="flex relative text-center leading-4">
                                                            <UserIcon
                                                                className="relative text-gray-400 hover:text-gray-500 top-[5px] h-7 cursor-pointer"
                                                                aria-hidden="true"
                                                            />

                                                            <span
                                                                className={
                                                                    'flex flex-col justify-start text-left text-xs ml-2'
                                                                }
                                                            >
                                                                <strong
                                                                    className={`block font-semibold text-sm cursor-pointer ${
                                                                        loginPopupOpen
                                                                            ? 'text-skin-primary-hover'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    Kundenkonto
                                                                </strong>
                                                                <div
                                                                    className={`cursor-pointer text-black ${
                                                                        loginPopupOpen
                                                                            ? 'text-skin-primary-hover'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    Anmelden
                                                                </div>
                                                            </span>
                                                        </span>
                                                        <div className="absolute top-0 left-1/2 -translate-x-1/2 bg-transparent h-12 w-full cursor-pointer" />
                                                        <LoginPopup
                                                            isSignedIn={
                                                                isSignedIn
                                                            }
                                                            handledSignOut={
                                                                handledSignOut
                                                            }
                                                            loginPopupOpen={
                                                                loginPopupOpen
                                                            }
                                                            setLoginPopupOpen={
                                                                setLoginPopupOpen
                                                            }
                                                        />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="relative"
                                                        onClick={() =>
                                                            setLoginPopupOpen(
                                                                true
                                                            )
                                                        }
                                                        tabIndex={0}
                                                        role="button"
                                                    >
                                                        <span className="flex relative text-center leading-4">
                                                            <UserIcon
                                                                className="relative text-gray-400 hover:text-gray-500 top-[5px] h-7 cursor-pointer"
                                                                aria-hidden="true"
                                                            />

                                                            <span
                                                                className={`flex flex-col justify-start text-left text-xs ml-2 ${
                                                                    loginPopupOpen
                                                                        ? 'text-skin-primary-hover'
                                                                        : ''
                                                                }`}
                                                            >
                                                                <strong className="block font-semibold text-sm cursor-pointer">
                                                                    Kundenkonto
                                                                </strong>
                                                                <div
                                                                    className={`cursor-pointer text-black ${
                                                                        loginPopupOpen
                                                                            ? 'text-skin-primary-hover'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    Anmelden
                                                                </div>
                                                            </span>
                                                        </span>
                                                        <LoginPopup
                                                            isSignedIn={
                                                                isSignedIn
                                                            }
                                                            handledSignOut={
                                                                handledSignOut
                                                            }
                                                            loginPopupOpen={
                                                                loginPopupOpen
                                                            }
                                                            setLoginPopupOpen={
                                                                setLoginPopupOpen
                                                            }
                                                        />
                                                    </div>
                                                )
                                            ) : !isMobile ? (
                                                <Link href="/account">
                                                    <div
                                                        className="relative"
                                                        onMouseEnter={() =>
                                                            setLoginPopupOpen(
                                                                true
                                                            )
                                                        }
                                                        onMouseLeave={() =>
                                                            setLoginPopupOpen(
                                                                false
                                                            )
                                                        }
                                                    >
                                                        <span className="flex relative text-center leading-4">
                                                            <UserIcon
                                                                className="relative text-gray-400 hover:text-gray-500 top-[5px] h-7 cursor-pointer"
                                                                aria-hidden="true"
                                                            />
                                                            <span
                                                                className={
                                                                    'flex flex-col justify-start text-left ml-2'
                                                                }
                                                            >
                                                                <strong
                                                                    className={`block text-sm font-semibold cursor-pointer ${
                                                                        loginPopupOpen
                                                                            ? 'text-skin-primary-hover'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    Kundenkonto
                                                                </strong>
                                                                <div
                                                                    className={`flex text-xs items-center cursor-pointer relative pl-5 text-black ${
                                                                        loginPopupOpen
                                                                            ? 'text-skin-primary-hover'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    <LogoutIcon
                                                                        className="w-4 h-6 text-gray-400 absolute -top-1 left-0"
                                                                        aria-hidden="true"
                                                                    />
                                                                    Abmelden
                                                                </div>
                                                            </span>
                                                        </span>
                                                        <div className="absolute top-0 left-1/2 -translate-x-1/2 bg-transparent h-12 w-full cursor-pointer" />
                                                        <LoginPopup
                                                            isSignedIn={
                                                                isSignedIn
                                                            }
                                                            handledSignOut={
                                                                handledSignOut
                                                            }
                                                            loginPopupOpen={
                                                                loginPopupOpen
                                                            }
                                                            setLoginPopupOpen={
                                                                setLoginPopupOpen
                                                            }
                                                        />
                                                    </div>
                                                </Link>
                                            ) : (
                                                <div
                                                    className="flex relative text-center leading-4"
                                                    onClick={() =>
                                                        setLoginPopupOpen(true)
                                                    }
                                                    tabIndex={0}
                                                    role="button"
                                                >
                                                    <UserIcon
                                                        className="relative text-gray-400 hover:text-gray-500 top-[5px] h-7 cursor-pointer"
                                                        aria-hidden="true"
                                                    />
                                                    <span
                                                        className={
                                                            'flex flex-col justify-start text-left ml-2'
                                                        }
                                                    >
                                                        <strong
                                                            className={`block text-sm font-semibold cursor-pointer active:text-skin-primary-hover ${
                                                                loginPopupOpen
                                                                    ? 'text-skin-primary-hover'
                                                                    : ''
                                                            }`}
                                                        >
                                                            Kundenkonto
                                                        </strong>
                                                        <div
                                                            className={`flex text-xs items-center cursor-pointer relative pl-5 text-black ${
                                                                loginPopupOpen
                                                                    ? 'text-skin-primary-hover'
                                                                    : ''
                                                            }`}
                                                        >
                                                            <LogoutIcon
                                                                className="w-4 h-6 text-gray-400 absolute -top-1 left-0"
                                                                aria-hidden="true"
                                                            />
                                                            Abmelden
                                                        </div>
                                                        <LoginPopup
                                                            isSignedIn={
                                                                isSignedIn
                                                            }
                                                            handledSignOut={
                                                                handledSignOut
                                                            }
                                                            setLoginPopupOpen={
                                                                setLoginPopupOpen
                                                            }
                                                            loginPopupOpen={
                                                                loginPopupOpen
                                                            }
                                                        />
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        {/* Search */}
                                        <div className="flex lg:ml-6 lg:hidden">
                                            <button className="p-2 text-gray-400 hover:text-gray-500">
                                                <span className="sr-only">
                                                    Search
                                                </span>
                                                <SearchIcon
                                                    className="w-6 h-6"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </div>
                                        {/* Wish list */}
                                        <div className="flex ml-4 xl:ml-6">
                                            <Link href={'/wishlist'} passHref>
                                                <a>
                                                    <span className="flex relative text-center leading-4 cursor-pointer hover:text-skin-primary-hover">
                                                        <HeartIcon
                                                            className="relative top-[5px] h-7 text-gray-400 hover:text-gray-500"
                                                            aria-hidden="true"
                                                        />

                                                        <span
                                                            className={
                                                                'flex flex-col justify-start text-left ml-2'
                                                            }
                                                        >
                                                            <strong className="block font-semibold text-sm cursor-pointer">
                                                                Wunschliste
                                                            </strong>
                                                            <span className="flex text-xs text-black">
                                                                <WishlistCount />
                                                            </span>
                                                        </span>
                                                    </span>
                                                </a>
                                            </Link>
                                        </div>

                                        {/* Cart */}
                                        <div className="ml-4 flow-root xl:ml-6 relative">
                                            <Link href="/cart">
                                                <a className="group -m-2 p-2 flex items-center hover:text-skin-primary-hover">
                                                    <span className="flex relative text-center leading-4">
                                                        <CartIcon
                                                            className="relative top-[5px] h-7 text-gray-400 hover:text-gray-500"
                                                            aria-hidden="true"
                                                        />

                                                        <span
                                                            className={
                                                                'flex flex-col justify-start text-left ml-2'
                                                            }
                                                        >
                                                            <strong className="block font-semibold text-sm cursor-pointer">
                                                                Warenkorb
                                                            </strong>
                                                            <span className="flex text-xs text-black">
                                                                <ProductCount />
                                                            </span>
                                                        </span>
                                                    </span>
                                                </a>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Browser Links */}
                        </div>
                    </div>
                </div>
                {/* Flyout menus */}
                <nav className="container-block relative">
                    <DesktopNavigation
                        isOpen={isOpen}
                        categories={categories}
                        featuredCategories={featuredCategories}
                        staticItems={staticItems}
                        toggleNavi={toggleNavi}
                        categoryUrlSuffix={categoryUrlSuffix}
                        loading={loadingNavi}
                    />
                </nav>
            </header>
        </div>
    );
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        toggleCart: () => dispatch(toggleDrawer('cart')),
        toggleNavi: () => dispatch(toggleDrawer('navi'))
    };
};

const mapStateToProps = ({
    user: { isSignedIn },
    app: { drawer }
}: mapStateToPropsType) => {
    return {
        isSignedIn,
        drawer
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

interface NavigationPropTypes {
    categories: object;
    loadingNavi: boolean;
    categoryUrlSuffix: string;
    toggleCart: Function;
    toggleNavi: Function;
    isSignedIn: boolean;
    drawer: string;
    storeConfig: Object;
}

type mapStateToPropsType = {
    user: {
        isSignedIn: boolean;
    };
    app: {
        drawer: string;
    };
};
