import { createStore, applyMiddleware, compose } from 'redux';
import reducer from './reducer';
import log from './middlewares/log';
import { persistStore } from 'redux-persist';
import { initialState as initialStateCart } from './reducer/cart';
/* eslint-disable no-underscore-dangle */
const composeEnhancers = typeof window !== 'undefined' && window.devToolsExtension ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;
/* eslint-enable */

const middlewares = [log];

const initialState = typeof window !== 'undefined' ? { cart: { ...initialStateCart, cartId: window.sessionStorage.getItem('cartId') } } : {};
export const store = createStore(reducer, initialState, composeEnhancers(
    applyMiddleware(...middlewares)
));

export const persistor = persistStore(store);
