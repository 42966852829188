import React from 'react';

const Labels = (props: LabelsProps) => {
    const { label, percent_off } = props;
    const labelClass = props.labelClass ? props.labelClass : 'w-fit mr-4';
    const renderLabel = () => {
        switch (label) {
            case 'discount':
                return (
                    <div className="py-1 px-2 bg-skin-red-bg text-white text-xs lg:text-sm font-normal">
                        {`-${percent_off}%`}
                    </div>
                );
            case 'topseller':
                return (
                    <div className="py-1 px-2 text-skin-green-secondary border border-solid border-skin-green-secondary text-white bg-white text-xs lg:text-sm font-semibold">
                        {`TOPSELLER`}
                    </div>
                );
            case 'sale':
                return (
                    <div className="py-1 px-2 text-skin-red border border-solid border-skin-red text-white bg-white text-xs lg:text-sm font-semibold">
                        {`SALE`}
                    </div>
                );
            case 'werbung':
                return (
                    <div className="py-1 px-2 text-skin-blue border border-solid border-skin-blue text-white bg-white text-xs lg:text-sm font-semibold">
                        {`WERBUNG`}
                    </div>
                );
            case 'optiangebote':
                return (
                    <div className="py-1 px-2 text-skin-red border border-solid border-skin-red text-white bg-white text-xs font-semibold">
                        {`OptiCard Preis`}
                    </div>
                );
            default:
                return null;
        }
    };

    return <div className={labelClass}>{renderLabel()}</div>;
};

interface LabelsProps {
    label: string;
    percent_off?: number;
    labelClass?: string;
    isTopseller?: string;
}

export default Labels;
