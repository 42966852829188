import React from 'react';
import { useQuery } from '@apollo/client';
import attributeCodeQuery from '../../queries/getAttributeCodeLabels.graphql';
import { string, number, oneOfType } from 'prop-types';

interface AttributeLabelProps {
    values: string;
    limit: string;
    attributeCode: string;
}

const AttributeLabel = (props: AttributeLabelProps) => {
    const { values, limit, attributeCode } = props;

    const { loading, data, error } = useQuery(attributeCodeQuery, {
        variables: { attribute_code: attributeCode }
    });

    const renderSelectAttribute = (
        loading: boolean,
        data: {
            customAttributeMetadata: {
                items: { attribute_options: [] }[];
            };
        },
        error?: object
    ) => {
        let displayLimit = 2;

        if (!values) {
            return null;
        }

        if (limit) {
            displayLimit = Number(limit);
        }

        if (error) {
            if (process.env.NODE_ENV !== 'production') {
                console.error(error);
            }
            return '';
        }
        if (loading) {
            return '';
        }

        const compareValues = values.toString().split(',');
        const labelsArr: string[] = [];

        compareValues.map((compareValue) => {
            data.customAttributeMetadata.items[0].attribute_options.map(
                (value: { value: string; label: string }) => {
                    // alert(value.value)
                    if (value.value == compareValue) {
                        labelsArr.push(value.label);
                    }
                }
            );
        });

        //Return initial value if no attribute is found. Usualy occurs when you have only a number in a text field attribute
        if (labelsArr.toString()) {
            return labelsArr.map((label, index) =>
                index <= displayLimit ? (
                    <span key={index} className="hyphensAuto">
                        {label}
                        {index <= displayLimit - 1 &&
                        index < labelsArr.length - 1
                            ? ','
                            : null}{' '}
                    </span>
                ) : labelsArr.length == index + 1 ? (
                    '...'
                ) : null
            );
        } else {
            return <span className="hyphensAuto">{values}</span>;
        }
    };

    return renderSelectAttribute(loading, data, error);
};

export default AttributeLabel;

AttributeLabel.propTypes = {
    attributeCode: string.isRequired,
    values: oneOfType([string, number]).isRequired,
    limit: string
};
