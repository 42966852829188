import { useQuery } from '@apollo/client';
import TOP_SELLER_PRODUCTS_QUERY from '../../queries/getTopSellerProducts.graphql';
import ProductBlock from '../ProductBlock';
import { homepageProductsLoadingTemplate } from '../LoadingContent/loadingTemplates';

const StoreProducts = (props: any) => {
    const { id } = props;

    const { loading, error, data } = useQuery(TOP_SELLER_PRODUCTS_QUERY, {
        variables: { id }
    });

    if (error) {
        return <span />;
    }
    if (loading) {
        return homepageProductsLoadingTemplate;
    }

    if (data.topSellerBlockProducts.sku == 'false') {
        return <span />;
    } else {
        return (
            <section>
                <ProductBlock
                    skus={data.topSellerBlockProducts.sku.split(',')}
                />
            </section>
        );
    }
};

export default StoreProducts;
