import { useState } from 'react';
import CopyIcon from '../../images/copy.svg';

const PromoCodeBtn = ({ code, id }: { code: string; id: string }) => {
    const [btnTetx, setBtnText] = useState(code);
    const copySuccessTxt = 'Kopiert!';

    return navigator ? (
        <div
            className={`flex text-xl md:text-3xl ${
                btnTetx === copySuccessTxt ? 'bg-skin-green' : 'bg-skin-blue-bg'
            } text-white rounded-xl shadow relative`}
        >
            <button
                className={`${
                    btnTetx !== copySuccessTxt ? 'pr-8 md:pr-16' : ''
                } px-4 md:px-8  py-2`}
                type="button"
                id={id}
                onClick={() => {
                    navigator.clipboard.writeText(code);
                    setTimeout(() => {
                        setBtnText(code);
                    }, 2000);
                    setBtnText(copySuccessTxt);
                }}
            >
                <div>{btnTetx}</div>
            </button>
            {btnTetx !== copySuccessTxt ? (
                <div>
                    <CopyIcon className="h-4 md:h-8 absolute right-3 md:right-7 top-1/2 -mt-2 md:-mt-4 pointer-events-none" />
                </div>
            ) : null}
        </div>
    ) : null;
};

export default PromoCodeBtn;
