const SEO = {
    title: 'Online günstige Leuchten für Ihr Zuhause kaufen | Jetzt bei Funkelhaus.de',
    description:
        'Funkelhaus.de | Lampen für Dein Zuhause ✓ Deckenleuchten ✓ Stehleuchten ✓ Hängeleuchten ✓ Einfach online bestellen.',
    openGraph: {
        type: 'website',
        locale: 'de_DE',
        url: 'https://funkelhaus.de/',
        site_name: 'Funkelhaus.de'
    }
};
export default SEO;
