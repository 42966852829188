import React from 'react';
import { string, shape, number, bool } from 'prop-types';
import { useQuery } from '@apollo/client';
import TOP_SELLER_BLOCK from '../../queries/getTopSellerBlock.graphql';
import StoreProducts from './storeProducts';
import { homepageProductsLoadingTemplate } from '../LoadingContent/loadingTemplates';

const TopSellerProducts = (props: any) => {
    const { id } = props;

    const { loading, error, data } = useQuery(TOP_SELLER_BLOCK, {
        variables: { block_id: id }
    });

    if (error) {
        return '';
    }

    if (loading) {
        return homepageProductsLoadingTemplate;
    }

    return (
        <StoreProducts
            {...props}
            id={data.topSellerBlock.id}
            title={data.topSellerBlock.frontend_name}
            columns={data.topSellerBlock.columns}
            slider={data.topSellerBlock.slider}
            verticalMode={data.topSellerBlock.vertical == null ? false : true}
            productsLimit={data.topSellerBlock.product_limit}
        />
    );
};

export default TopSellerProducts;

TopSellerProducts.propTypes = {
    classes: shape({
        root: string
    }),
    id: string.isRequired,
    maxProducts: number,
    noHeadline: bool,
    category_id: number,
    pagination: bool,
    pright: string,
    pleft: string,
    topsellerStyles: bool
};
