import Heart from './images/heart.svg';
import HeartMobile from './images/heart_icon_mobile.svg';
import HeartActive from './images/heart_active.svg';
import HeartActiveMobile from './images/heart_active_mobile.svg';
import { useWishlist } from '../../talons/WishList/useWishList';
import { connect } from 'react-redux';
import { TrashIcon } from '@heroicons/react/outline';

interface AddToWishListButtonProps {
    product: {
        sku?: string;
    };
    customerWishListId: number;
    customerWishlistProducts: { id: string; product: { sku: string } }[];
    isGuestWishlist: boolean;
    guestWishlistProducts: string[] | null;
    isTrashIcon: boolean;
}

const AddToWishListButton = ({
    product,
    customerWishListId,
    customerWishlistProducts,
    isGuestWishlist,
    guestWishlistProducts,
    isTrashIcon
}: AddToWishListButtonProps) => {
    const productWishlistId: { id: string; product: { sku: string } }[] | null =
        !isGuestWishlist
            ? customerWishlistProducts?.filter(
                  (f: { product: { sku: string } }) =>
                      f.product.sku === product.sku
              )
            : null;

    const itemId =
        Array.isArray(productWishlistId) && productWishlistId.length > 0
            ? productWishlistId[0].id
            : null;
    const active = !isGuestWishlist
        ? Array.isArray(productWishlistId) && productWishlistId.length > 0
        : Array.isArray(guestWishlistProducts) &&
          product.sku &&
          guestWishlistProducts.includes(product.sku);

    const preparedItem = {
        quantity: 1,
        sku: product.sku
    };

    const { addProductToWishList, removeProductFromWishList, wishlistLoading } =
        useWishlist({
            customerWishListId,
            item: preparedItem,
            itemId,
            isGuestWishlist
        });

    return !isTrashIcon ? (
        <button
            aria-label="Wunschliste"
            onClick={(e) => {
                e.preventDefault();
                active ? removeProductFromWishList() : addProductToWishList();
            }}
            className={
                'absolute top-4 right-4 disabled:opacity-50 flex items-center'
            }
            disabled={wishlistLoading}
        >
            {active ? (
                <span>
                    <HeartActive className="hidden md:block" />
                    <HeartActiveMobile className="block md:hidden" />
                </span>
            ) : (
                <span>
                    <Heart className="hidden md:block" />
                    <HeartMobile className="block md:hidden" />
                </span>
            )}
        </button>
    ) : (
        <button
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                removeProductFromWishList();
            }}
            className="disabled:opacity-50 sm:static"
            disabled={wishlistLoading}
        >
            <TrashIcon className="h-8 w-8 hover:text-skin-bolder-hover duration-150 cursor-pointer" />
        </button>
    );
};

const mapStateToProps = ({
    wishlist,
    user
}: {
    wishlist: {
        isGuestWishlist: boolean;
        wishlistData: { wishlist: { items: [] } };
    };
    user: { currentUser: { wishlist: { id: number } } };
}) => {
    const customerWishListId: number = user?.currentUser?.wishlist?.id;

    const customerWishlistProducts: [] =
        wishlist?.wishlistData?.wishlist?.items;
    const guestWishlistProducts: string[] | null = wishlist?.isGuestWishlist
        ? wishlist?.wishlistData?.wishlist?.items
        : null;
    return {
        customerWishListId,
        customerWishlistProducts,
        isGuestWishlist: wishlist?.isGuestWishlist,
        guestWishlistProducts
    };
};

export default connect(mapStateToProps)(AddToWishListButton);
