import React from 'react';

const TopBanner = () => {
    return (
        <div className="bg-skin-site-bg-alternate hidden lg:block">
            <div className="container mx-auto my-0 px-5">
                <ul className="styled-list flex justify-center items-center py-2">
                    <li className="mb-0 mr-4 text-xs text-black font-normal ">
                        Über 4.000 Leuchten auf Lager
                    </li>
                    <li className="mb-0 mr-4 text-xs text-black font-normal">
                        Lieferung direkt vom Hersteller
                    </li>
                    <li className="mb-0 mr-4 text-xs text-black font-normal">
                        30 Tage Geld-zurück-Garantie
                    </li>
                    <li className="mb-0 mr-4 text-xs text-black font-normal">
                        Versandkostenfrei ab 50,- €
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default TopBanner;
