export const initialState = {
    addItemError: null,
    cartId: null,
    details: null,
    isLoading: false,
    isAddingItem: false,
    isUpdatingItem: false,
    isRemovingItem: false,
    detailsError: null,
    updateItemError: [],
    removeItemError: null
};

const cart = (state = initialState, action) => {
    switch (action.type) {
        case 'CREATE_CART':
            return {
                ...state,
                addItemError: action.payload.addItemError,
                cartId: action.payload.cartId
            };
        case 'CREATE_CART_DETAILS':
            return {
                ...state,
                details: action.payload
            };
        case 'CREATE_CART_STATE':
            return {
                ...state,
                cartId: action.payload.cartId,
                detailsError: action.payload.detailsError,
                updateItemError: action.payload.updateItemError,
                addItemError: action.payload.addItemError,
                removeItemError: action.payload.removeItemError
            };
        case 'RESET_CART_STATE':
            return {
                ...initialState
            }
        case 'IS_UPDATING_ITEM_IN_CART':
            return {
                ...state,
                isUpdatingItem: action.payload,
                isLoading: action.payload
            }
        case 'IS_ADDING_ITEM_IN_CART':
            return {
                ...state,
                isAddingItem: action.payload,
                isLoading: action.payload
            }
        case 'IS_REMOVING_ITEM_IN_CART':
            return {
                ...state,
                isRemovingItem: action.payload,
                isLoading: action.payload
            }
        default:
            return state;
    }
};

export default cart;
