import React from 'react';
import DropshipTime from './dropshipTime';
import convertToWeeks from '../../util/convertToWeeks';
import checkSpedition from '../../util/checkSpedition';
import AttributeLabel from '../AttributeLabel';

interface DeliveryTimePlainTypes {
    sku: string;
    deliveryType: number;
    selectedQuantity: number;
    deliveryInfo?: any;
    mhsStock: string;
    deliveryTime: number;
    wishlist?: boolean;
    productState?: string;
    rootClass?: string;
}

const DeliveryTime = (props: DeliveryTimePlainTypes) => {
    const {
        sku,
        deliveryType,
        deliveryTime,
        selectedQuantity,
        //deliveryInfo,
        mhsStock,
        wishlist,
        productState,
        rootClass
    } = props;

    const isSpedition = checkSpedition(deliveryType).isSpedition;

    // Add 2 days for package and 8 days for spedition
    const deliveryTimeAdjusted = isSpedition
        ? Number(deliveryTime) + 8
        : Number(deliveryTime) + 2;
    const deliveryTimeWeeks = convertToWeeks(deliveryTimeAdjusted);

    const handledMhsStorePacket =
        mhsStock != '' ? JSON.parse(mhsStock).store_stock : null;
    // const handledZoneStock =
    //     productMhsStock != '' ? JSON.parse(productMhsStock).zone_stock : null;
    //const selectedMhsInfoDelivery = handledMhsStorePacket[deliveryInfo];
    const onlineStock =
        handledMhsStorePacket && handledMhsStorePacket['online'].stock
            ? handledMhsStorePacket['online'].stock
            : null;
    // const zoneStock = handledZoneStock[selectedMhsInfoDelivery?.zone]
    //     ? handledZoneStock[selectedMhsInfoDelivery.zone].stock
    //     : null;
    const dropshipingStock =
        handledMhsStorePacket && handledMhsStorePacket['dropshipping']
            ? handledMhsStorePacket['dropshipping'].stock
            : null;
    console.log('Dropshipping: ', dropshipingStock);
    console.log('Shipping time: : ', deliveryTime);

    return (
        <div>
            {productState == 'N' ? (
                <span className="text-sm text-skin-orange">
                    Dieser Artikel ist nur in unseren Filialen erhältlich.
                </span>
            ) : productState == '4' && onlineStock <= 0 ? (
                <span className="text-sm text-skin-orange">
                    Dieser Artikel ist aktuell nicht bestellbar.
                </span>
            ) : (productState == '8' ||
                  productState == 'L' ||
                  productState == '9' ||
                  productState == 'S') &&
              onlineStock <= 0 ? (
                <span className="text-sm text-skin-orange">
                    Dieser Artikel ist leider ausverkauft.
                </span>
            ) : (
                <span className="font-semibold text-left">
                    {onlineStock > 0 && onlineStock >= selectedQuantity ? (
                        isSpedition ? (
                            <span className="text-sm text-skin-green">
                                Auf Lager – Innerhalb 2 Wochen
                            </span>
                        ) : (
                            <span className="text-sm text-skin-green">
                                {deliveryType == 26045
                                    ? 'Auf Lager – 2-4 Werktage' // Spergut
                                    : 'Auf Lager – 2-3 Werktage'}
                            </span>
                        )
                    ) : dropshipingStock > 0 &&
                      dropshipingStock >= selectedQuantity ? (
                        <span className="font-semibold text-skin-green">
                            <DropshipTime sku={sku} rootClass={rootClass} />
                        </span>
                    ) : deliveryTime != null ? (
                        <span className="text-sm text-skin-orange">
                            {deliveryTimeAdjusted > 28
                                ? 'Bestellbar - Über 4 Wochen'
                                : 'Bestellbar - Innerhalb ' + deliveryTimeWeeks}
                        </span>
                    ) : null}

                    {deliveryType && !wishlist ? (
                        <span className="block text-sm">
                            Ware wird als{' '}
                            <AttributeLabel
                                attributeCode="delivery_type"
                                values={deliveryType}
                            />{' '}
                            verschickt
                        </span>
                    ) : null}
                </span>
            )}
        </div>
    );
};

export default DeliveryTime;
