import React, { useState } from 'react';
import SearchIcon from './img/search_icon.svg';
import AutocompleteWindow from './autocompleteWindow';
import router from 'next/router';
import { useCookies } from 'react-cookie';

const SearchBarDesktop = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [autocompleteData, setAutocompleteData] =
        useState<AutocompleteData | null>({
            products: [],
            textSuggestions: [],
            attributeSuggestions: { vendor: [], cat: [] }
        });
    const [cookies, setCookie, removeCookie] = useCookies(['search_history']);

    // navigate on submit
    const handleSubmit = (
        e: React.FormEvent<HTMLFormElement>,
        value: string
    ) => {
        e.preventDefault();

        router.push(`/suche?query=${value}`);

        if (cookies.search_history) {
            const searchHistoryList = cookies.search_history.split(',');

            if (searchHistoryList.length == 5) {
                searchHistoryList.shift();
                searchHistoryList.push(value);
            } else {
                !searchHistoryList.includes(value) &&
                    searchHistoryList.push(value);
            }

            setCookie('search_history', searchHistoryList.join(','), {
                path: '/',
                maxAge: 60 * 60 * 24 * 14 // lasts for 14 days
            });
        } else {
            setCookie('search_history', value, {
                path: '/',
                maxAge: 60 * 60 * 24 * 14
            });
        }

        handleClose();
    };

    const handleClose = () => {
        setIsOpen(false);
        document.body.classList.remove('disabled_scroll');
    };

    const inputText = () => {
        return router.query.query
            ? (router.query.query as string)
            : router.query.cat
            ? (router.query.cat as string)
            : 'Suchbegriff eingeben';
    };

    return (
        <div className="w-full px-12 lg:px-20 xl:px-40">
            <div
                role="presentation"
                onClick={() => {
                    setIsOpen(true);
                    document.body.classList.add('disabled_scroll');
                }}
                className="flex items-center justify-center w-full cursor-pointer"
            >
                <div className="text-sm w-full mr-2.5 p-1.5 border-solid border-b border-skin-blue outline-none placeholder-current">
                    {inputText()}
                </div>
                <div>
                    <SearchIcon className="mt-[3px] h-7" />
                </div>
            </div>
            <AutocompleteWindow
                setAutocompleteData={setAutocompleteData}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                isOpen={isOpen}
                autocompleteData={autocompleteData}
                searchHistory={
                    cookies.search_history
                        ? cookies.search_history.split(',')
                        : null
                }
                setCookie={setCookie}
                removeCookie={removeCookie}
            />
        </div>
    );
};

interface AutocompleteData {
    products?: [];
    textSuggestions?: [];
    attributeSuggestions?: { vendor: []; cat: [] };
}

export default SearchBarDesktop;
