import React from 'react';

interface OverlayProps {
    children?: JSX.Element;
}

const LocalOverlay = (props: OverlayProps) => {
    const { children } = props;

    return (
        <div className="absolute top-0 left-0 flex justify-center items-center h-full w-full bg-white bg-opacity-50 z-40">
            <div className="mx-3">{children}</div>
        </div>
    );
};

export default LocalOverlay;
