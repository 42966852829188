export const cheapestShippingMethod = (allMethods) => {
    const handledMethods = allMethods
        ?.filter((f) => f.code !== 'pickup')
        ?.sort((a, b) => Number(a.amount) - Number(b.amount));

    const availableShippingMethodAmount =
        handledMethods &&
        Array.isArray(handledMethods) &&
        handledMethods.length > 0
            ? {
                  price: Number(handledMethods[0].amount),
                  code: handledMethods[0].code,
                  name: handledMethods[0].name
              }
            : null;

    return availableShippingMethodAmount;
};
