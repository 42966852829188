export const toggleDrawer = (payload) => {
    return {
        type: 'TOGGLE_DRAWER',
        payload
    };
};
export const toggleSearch = (payload) => {
    return {
        type: 'TOGGLE_SEARCH',
        payload
    };
};

export const setCookiesPolicies = (payload) => {
    return {
        type: 'SET_COOKIES_POLICIES',
        payload
    };
};

export const setInitialCookiesPolicies = (payload) => {
    return {
        type: 'SET_INITIAL_COOKIES_POLICIES',
        payload
    };
};