import React, { useState } from 'react';
import KlarnaIcon from '../../images/payment_klarna.svg';
import PPIcon from '../../images/payment_pp.svg';
// import DPDIcon from '../../images/dpd.svg';
import DHLIcon from '../../images/dhl.svg';
import FBIcon from '../../images/facebook.svg';
import InstagramIcon from '../../images/instagram.svg';
import PinterestIcon from '../../images/pinterest.svg';
import YTIcon from '../../images/youtube.svg';
import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/outline';
import CmsBlock from '../CmsBlock/cmsBlock';

const Footer = () => {
    const [isOpen, setIsOpen] = useState('services');

    const date = new Date();
    const currentYear = date.getFullYear();

    return (
        <footer className="body-font">
            {/* Desktop */}
            <div className="hidden content-container py-24 mx-auto lg:flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
                <div className="flex-grow flex flex-wrap -mb-10 md:mt-0 mt-10 md:text-left text-center">
                    <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2>Services</h2>
                        <CmsBlock identifiers={['footer_services_links']} />
                    </div>
                    <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2>Unternehmen</h2>
                        <CmsBlock identifiers={['footer_about_links']} />
                    </div>
                    <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2 className="">Zahlungsarten</h2>
                        <div className="flex flex-start justify-start flex-wrap items-center">
                            <KlarnaIcon className={'w-14 mb-4 mr-4'} />
                            <PPIcon className={'w-14 mb-4 mr-4'} />
                            <span className="flex justify-center mt-1 font-bold mr-4 mb-4">
                                Vorkasse
                            </span>
                        </div>
                    </div>
                    <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2>Versandarten</h2>
                        <div className="flex flex-start justify-start flex-wrap items-center">
                            {/* <DPDIcon className="w-14 mb-4 mr-4" /> */}
                            <DHLIcon className="w-14 mb-4" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile */}
            <div className="block lg:hidden container-block">
                <div>
                    <Disclosure
                        as="div"
                        key={'footer_services'}
                        onClick={() =>
                            isOpen == 'services'
                                ? setIsOpen('')
                                : setIsOpen('services')
                        }
                    >
                        <div className="w-full border-solid border-b border-skin-primary">
                            <Disclosure.Button className="block w-full text-left text-lg font-bold py-4">
                                <span className="flex justify-between items-center">
                                    Services{' '}
                                    {isOpen == 'services' ? (
                                        <ChevronDownIcon className="h-6 w-6" />
                                    ) : (
                                        <ChevronRightIcon className="h-6 w-6" />
                                    )}
                                </span>
                            </Disclosure.Button>
                            {isOpen == 'services' && (
                                <Disclosure.Panel static className="p-2 pt-0">
                                    <CmsBlock
                                        identifiers={['footer_services_links']}
                                    />
                                </Disclosure.Panel>
                            )}
                        </div>
                    </Disclosure>
                    <Disclosure
                        as="div"
                        key={'footer_uternehmen'}
                        onClick={() =>
                            isOpen == 'unternehmen'
                                ? setIsOpen('')
                                : setIsOpen('unternehmen')
                        }
                    >
                        <div className="w-full border-solid border-b border-skin-primary">
                            <Disclosure.Button className="block w-full text-left text-lg font-bold py-4">
                                <span className="flex justify-between items-center">
                                    Unternehmen{' '}
                                    {isOpen == 'unternehmen' ? (
                                        <ChevronDownIcon className="h-6 w-6" />
                                    ) : (
                                        <ChevronRightIcon className="h-6 w-6" />
                                    )}
                                </span>
                            </Disclosure.Button>
                            {isOpen == 'unternehmen' && (
                                <Disclosure.Panel static className="p-2 pt-0">
                                    <CmsBlock
                                        identifiers={['footer_about_links']}
                                    />
                                </Disclosure.Panel>
                            )}
                        </div>
                    </Disclosure>
                    <Disclosure
                        as="div"
                        key={'footer_zahlungsarten'}
                        onClick={() =>
                            isOpen == 'zahlungsarten'
                                ? setIsOpen('')
                                : setIsOpen('zahlungsarten')
                        }
                    >
                        <div className="w-full border-solid border-b border-skin-primary">
                            <Disclosure.Button className="block w-full text-left text-lg font-bold py-4">
                                <span className="flex justify-between items-center">
                                    Zahlungsarten{' '}
                                    {isOpen == 'zahlungsarten' ? (
                                        <ChevronDownIcon className="h-6 w-6" />
                                    ) : (
                                        <ChevronRightIcon className="h-6 w-6" />
                                    )}
                                </span>
                            </Disclosure.Button>
                            {isOpen == 'zahlungsarten' && (
                                <Disclosure.Panel static className="p-2 pt-0">
                                    <div className="flex flex-start justify-start flex-wrap items-center">
                                        <KlarnaIcon className={'w-12 mr-4'} />
                                        <PPIcon className={'w-12 mr-4'} />
                                        <span className="flex justify-center mt-1 font-bold mr-4">
                                            Vorkasse
                                        </span>
                                    </div>
                                </Disclosure.Panel>
                            )}
                        </div>
                    </Disclosure>
                    <Disclosure
                        as="div"
                        key={'footer_versandarten'}
                        onClick={() =>
                            isOpen == 'versandarten'
                                ? setIsOpen('')
                                : setIsOpen('versandarten')
                        }
                    >
                        <div className="w-full border-solid border-b border-skin-primary">
                            <Disclosure.Button className="block w-full text-left text-lg font-bold py-4">
                                <span className="flex justify-between items-center">
                                    Versandarten{' '}
                                    {isOpen == 'versandarten' ? (
                                        <ChevronDownIcon className="h-6 w-6" />
                                    ) : (
                                        <ChevronRightIcon className="h-6 w-6" />
                                    )}
                                </span>
                            </Disclosure.Button>
                            {isOpen == 'versandarten' && (
                                <Disclosure.Panel static className="p-2 pt-0">
                                    <div className="flex flex-start justify-start flex-wrap items-center">
                                        {/* <DPDIcon className="w-12 mr-4" /> */}
                                        <DHLIcon className="w-12" />
                                    </div>
                                </Disclosure.Panel>
                            )}
                        </div>
                    </Disclosure>
                    <div className="flex flex-center justify-center flex-wrap items-center p-4">
                        <a
                            aria-label="Facebook"
                            href="https://www.facebook.com/funkelhaus/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FBIcon className={'w-8 h-8 mr-4 cursor-pointer'} />
                        </a>
                        <a
                            aria-label="Instagram"
                            href="https://instagram.com/funkelhaus.de?igshid=YmMyMTA2M2Y="
                            target="_blank"
                            rel="noreferrer"
                        >
                            <InstagramIcon
                                className={'w-8 h-8 mr-4 cursor-pointer'}
                            />
                        </a>
                        <a
                            aria-label="Pinterest"
                            href="https://www.pinterest.de/funkelhaus/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <PinterestIcon
                                className={'w-8 h-8 mr-4 cursor-pointer'}
                            />
                        </a>
                        <a
                            aria-label="Youtube"
                            href="https://www.youtube.com/channel/UCzkOPX0OrCZH5jGlkJibZcg"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <YTIcon className={'w-8 h-8 cursor-pointer'} />
                        </a>
                    </div>
                </div>
            </div>
            <div className="py-2 lg:py-6 bg-skin-secondary text-white">
                <div className="flex flex-col-reverse items-center justify-center content-container w-full h-full sm:flex-row sm:justify-between">
                    <div>
                        <span className="text-xs mt-4 sm:mt-0 sm:text-sm">
                            © {currentYear} Opti-Wohnwelt Föst GmbH & Co. KG
                        </span>
                        <span className="text-xs block sm:text-sm lg:inline lg:ml-6">
                            *Unverbindliche Preisempfehlung des Herstellers
                        </span>
                    </div>
                    <div className="flex flex-center justify-center items-center px-4">
                        <a
                            aria-label="Facebook"
                            href="https://www.facebook.com/funkelhaus/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FBIcon
                                className={
                                    'w-6 lg:w-8 h-6 lg:h-8 mr-4 cursor-pointer'
                                }
                            />
                        </a>
                        <a
                            aria-label="Instagram"
                            href="https://instagram.com/funkelhaus.de?igshid=YmMyMTA2M2Y="
                            target="_blank"
                            rel="noreferrer"
                        >
                            <InstagramIcon
                                className={
                                    'w-6 lg:w-8 h-6 lg:h-8 mr-4 cursor-pointer'
                                }
                            />
                        </a>
                        <a
                            aria-label="Pinterest"
                            href="https://www.pinterest.de/funkelhaus/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <PinterestIcon
                                className={
                                    'w-6 lg:w-8 h-6 lg:h-8 mr-4 cursor-pointer'
                                }
                            />
                        </a>
                        <a
                            aria-label="Youtube"
                            href="https://www.youtube.com/channel/UCzkOPX0OrCZH5jGlkJibZcg"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <YTIcon
                                className={
                                    'w-6 lg:w-8 h-6 lg:h-8 cursor-pointer'
                                }
                            />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
