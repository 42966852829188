import React from 'react';
import { CheckIcon } from '@heroicons/react/solid';
import { BasicCheckbox, asField } from 'informed';
import { compose } from 'redux';
import { FieldApi } from 'informed';
import ReactHtmlParser from 'html-react-parser';

const Checkbox = (props: CheckboxProps) => {
    const {
        className,
        field,
        fieldState,
        fieldApi,
        label,
        initialValue,
        isRequired,
        disabled,
        skipParse,
        ...rest
    } = props;
    const { value: checked } = fieldState!;

    return fieldState && fieldApi ? (
        <div className={className ? className : ''}>
            <label>
                <BasicCheckbox
                    {...rest}
                    fieldState={fieldState}
                    disabled={disabled}
                    className="hideDefaultCheckbox"
                    fieldApi={fieldApi}
                />
                <span
                    className={`relative inline-block h-4 w-4 min-w-[16px] bg-white border border-solid border-skin-primary cursor-pointer ${
                        disabled ? 'opacity-50' : ''
                    }`}
                    aria-hidden="true"
                >
                    {(checked || initialValue) && (
                        <CheckIcon className="absolute left-2/3 top-2/4 h-6 w-6 -translate-x-2/4 -translate-y-2/4 text-skin-bolder-hover" />
                    )}
                </span>
            </label>
            {label && !skipParse ? (
                <span className="checkboxLabel ml-2">
                    {ReactHtmlParser(label)}
                    {isRequired && <span className="text-skin-red"> *</span>}
                </span>
            ) : label ? (
                <span
                    className="checkboxLabel ml-2"
                    dangerouslySetInnerHTML={{ __html: label }}
                />
            ) : null}
        </div>
    ) : null;
};

interface CheckboxProps {
    className?: string;
    field: string;
    fieldState?: any;
    fieldApi?: FieldApi;
    label?: string;
    initialValue?: boolean;
    isRequired?: boolean;
    disabled?: boolean;
    skipParse?: boolean;
}

export default compose(asField)(Checkbox);
