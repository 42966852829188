import React from 'react';
import { useState, useEffect } from 'react';
import moment from 'moment-timezone';

const Timer = ({
    dateString,
    finalMessage
}: {
    dateString: string;
    finalMessage?: string;
}) => {
    const handledDateString = moment(dateString).format('YYYY-MM-DD hh:mm:ss');
    const [expiryTime, setExpiryTime] = useState<any>(handledDateString); //dateString ex: '2023-3-30 17:10:00'
    const [countdownTime, setCountdownTime] = useState<{
        countdownDays: number | string;
        countdownHours: number | string;
        countdownMinutes: number | string;
        countdownSeconds: number | string;
    }>({
        countdownDays: 0,
        countdownHours: 0,
        countdownMinutes: 0,
        countdownSeconds: 0
    });
    const countdownTimer = () => {
        const timeInterval = setInterval(() => {
            const countdownDateTime = new Date(expiryTime).getTime();
            const currentTime = new Date().getTime();
            const remainingDayTime = countdownDateTime - currentTime;
            const totalDays = Math.floor(
                remainingDayTime / (1000 * 60 * 60 * 24)
            );
            const totalHours = Math.floor(
                (remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const totalMinutes = Math.floor(
                (remainingDayTime % (1000 * 60 * 60)) / (1000 * 60)
            );
            const totalSeconds = Math.floor(
                (remainingDayTime % (1000 * 60)) / 1000
            );

            const runningCountdownTime: {
                countdownDays: number;
                countdownHours: number;
                countdownMinutes: number;
                countdownSeconds: number;
            } = {
                countdownDays: totalDays,
                countdownHours: totalHours,
                countdownMinutes: totalMinutes,
                countdownSeconds: totalSeconds
            };

            setCountdownTime(runningCountdownTime);

            if (remainingDayTime < 0) {
                clearInterval(timeInterval);
                setExpiryTime(false);
            }
        }, 1000);
    };

    useEffect(() => {
        countdownTimer();
    }, [expiryTime]);

    return (
        <>
            <span className="text-xs">
                {expiryTime !== false ? (
                    <strong className="underline">
                        {countdownTime.countdownDays < 10
                            ? '0' + Number(countdownTime.countdownDays)
                            : Number(countdownTime.countdownDays)}
                        :
                        {countdownTime.countdownHours < 10
                            ? '0' + Number(countdownTime.countdownHours)
                            : Number(countdownTime.countdownHours)}
                        :
                        {countdownTime.countdownMinutes < 10
                            ? '0' + Number(countdownTime.countdownMinutes)
                            : Number(countdownTime.countdownMinutes)}
                        :
                        {countdownTime.countdownSeconds < 10
                            ? '0' + Number(countdownTime.countdownSeconds)
                            : Number(countdownTime.countdownSeconds)}
                    </strong>
                ) : finalMessage ? (
                    <span>{finalMessage}</span>
                ) : (
                    <span>00:00:00:00</span>
                )}
            </span>
        </>
    );
};

export default Timer;
