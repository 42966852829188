export const name = 'pickupAndDelivery';

const initialState = {
    deliveryInfo: null,
    pickupInfo: null,
    cartPickupDeliveryState: "delivery",
    pickupAddress: {}
};

const pickupAndDelivery = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_DELIVERY_INFO':
            return {
                ...state,
                deliveryInfo: action.payload
            };
        case 'ADD_PICKUP_INFO':
            return {
                ...state,
                pickupInfo: action.payload
            };
        case 'SET_CARTPICKUO_AND_DELIVERY_STATE':
            return {
                ...state,
                cartPickupDeliveryState: action.payload
            };
        default:
            return state;
    }
};

export default pickupAndDelivery;

