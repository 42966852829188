const isSignedIn = () => typeof window !== "undefined" && localStorage.getItem('signin_token') ? false : true;

const initialState = {
    isGuestWishlist: isSignedIn(),
    wishlistData: undefined
}

const wishlist = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_TO_WISHLIST':
            return {
                ...state,
                wishlistData: action.payload,
                isGuestWishlist: isSignedIn()
            };
        case 'REMOVE_FROM_WISHLIST':
            return {
                ...state,
                wishlistData: action.payload,
                isGuestWishlist: isSignedIn()
            };
        case 'UPDATE_WISHLIST':
            return {
                ...state,
            };
        case 'SET_WISHLIST_MODE':
            return {
                ...state,
                isGuestWishlist: action.payload,
            }

        case 'RESET_WISHLIST':
            return {
                isGuestWishlist: isSignedIn(),
                wishlistData: undefined
            }
        case 'MERGE_WISHLISTS':


            return {
                isGuestWishlist: isSignedIn(),
                wishlistData: undefined
            }
        default:
            return state;
    }
}

export default wishlist;