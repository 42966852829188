import { ApolloProvider } from '@apollo/client';
import { useApollo } from '~/lib/apollo-client';
import { withCookies } from 'react-cookie';
import { compose } from 'redux';
import Script from 'next/script';
import { DefaultSeo } from 'next-seo';
import SEO from '../next-seo.config';
import App from '~/components/App';
import 'reset-css';
import '~/styles/global.scss';
import { withPasswordProtect } from '@storyofams/next-password-protect';
import {
    FBPixelScript,
    FBPixelProvider
} from '@rivercode/facebook-conversion-api-nextjs/components';

function NextApp({ Component, pageProps, cookies }) {
    // console.log("localStorage; ", localStorage)
    const token = cookies.get('authToken');
    const apolloClient = useApollo(pageProps.initialApolloState, token);
    return (
        <ApolloProvider client={apolloClient}>
            <FBPixelScript />
            <FBPixelProvider>
                <App>
                    <DefaultSeo {...SEO} />
                    <Script id="google-tag-manager" strategy="afterInteractive">
                        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-5FCK6HV');
      `}
                    </Script>
                    <Component {...pageProps} />
                </App>
            </FBPixelProvider>
        </ApolloProvider>
    );
}

export default process.env.PASSWORD_PROTECT
    ? withPasswordProtect(compose(withCookies)(NextApp), {
          // Options go here (optional)
          loginApiUrl: '/api/frontLogin'
      })
    : compose(withCookies)(NextApp);
