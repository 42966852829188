import React from 'react';
import Link from 'next/link';
import Price from '../Price';
import Image from 'next/image';
import AddToWishListButton from '../Products/AddToWishListButton';
import AddToCartSection from '../Product/AddToCartSection';
import Labels from '../Labels/Labels';
import { isMobile } from 'react-device-detect';
import AttributeLabel from '../AttributeLabel';
import Variants from './variants';

const ProductCard = (props) => {
    const { product, showAddToCartButton, blured, wishListCustomerView } =
        props;
    const thumbnailUrl = product.thumbnail.url
        ? new URL(product.thumbnail.url)?.pathname
        : '/pub/media/catalog/product/placeholder/default/placeholder.jpg';
    const isFastDelivery =
        Number(product?.fast_delivery) == 1 ? ' Auf Lager' : null;
    const isRelatedColor = product?.related_color;
    const isRelatedSize = product?.related_size;
    return (
        <div
            className={
                blured
                    ? 'relative cursor-pointer blur-sm h-full'
                    : 'relative cursor-pointer h-full'
            }
            key={product.id}
        >
            <Link
                passHref
                href={`/${product.url_key}.html`}
                as={`/${product.url_key}.html`}
            >
                <a className="flex flex-col h-full hover:text-skin-base">
                    <div
                        key={product.id}
                        className="group pb-4 border border-solid border-product-card h-full flex flex-col justify-between bg-white"
                    >
                        <div>
                            {product &&
                            product.thumbnail &&
                            product.thumbnail.url ? (
                                <div
                                    className={
                                        wishListCustomerView && isMobile
                                            ? 'text-center w-full bg-white overflow-hidden group-hover:opacity-75'
                                            : 'w-full bg-white aspect-w-1 aspect-h-1 overflow-hidden group-hover:opacity-75'
                                    }
                                >
                                    {wishListCustomerView && isMobile ? (
                                        <Image
                                            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO8Uw8AAj0BXe1cD0IAAAAASUVORK5CYII="
                                            // layout="fill"
                                            width={150}
                                            height={150}
                                            //unoptimized={true}
                                            src={thumbnailUrl}
                                            alt={product.thumbnail.label}
                                            sizes="(max-width: 700px) 100px, (max-width: 1024px) 250px, (min-width: 1025px) 350px"
                                            className={
                                                '!max-w-[90%] !max-h-[90%] !min-w-[90%] !min-h-[90%] object-center object-contain'
                                            }
                                        />
                                    ) : (
                                        <Image
                                            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO8Uw8AAj0BXe1cD0IAAAAASUVORK5CYII="
                                            layout="fill"
                                            width={150}
                                            height={150}
                                            sizes="(max-width: 700px) 100px, (max-width: 1024px) 250px, (min-width: 1025px) 350px"
                                            //unoptimized={true}
                                            src={thumbnailUrl}
                                            alt={product.thumbnail.label}
                                            className={
                                                '!max-w-[90%] !max-h-[90%] !min-w-[90%] !min-h-[90%] object-center object-contain'
                                            }
                                        />
                                    )}
                                </div>
                            ) : null}
                            <div className="px-4">
                                <h3 className="line-clamp-2 group-hover:text-skin-primary-hover whitespace-normal">
                                    <span className="block text-xs text-left text-skin-muted min-h-[14px]">
                                        <AttributeLabel
                                            attributeCode="brand"
                                            values={product.brand}
                                        />
                                    </span>

                                    {product.name}
                                </h3>
                            </div>
                        </div>
                        <div>
                            <div className="flex justify-between flex-col items-start w-full px-4">
                                <span
                                    className={
                                        'absolute top-4 md:top-5 left-0 flex flex-row justify-start items-start sm:items-center'
                                    }
                                >
                                    {product.price_range.minimum_price.discount
                                        .amount_off > 0 && (
                                        <span className="mb-1 sm:mb-0">
                                            <Labels
                                                label="discount"
                                                percent_off={Math.ceil(
                                                    product.price_range
                                                        .minimum_price.discount
                                                        .percent_off
                                                )}
                                            />
                                        </span>
                                    )}

                                    {Number(product.werbung) == 1 && (
                                        <Labels label="werbung" />
                                    )}

                                    {Number(product.werbung) != 1 &&
                                        product?.product_state?.trim() ==
                                            'Y' && <Labels label="topseller" />}

                                    {product?.product_state?.trim() != 'Y' &&
                                        Number(product.werbung) != 1 &&
                                        product?.price_tag?.includes('40') && (
                                            <Labels label="optiangebote" />
                                        )}

                                    {!product?.price_tag?.includes('40') &&
                                        Number(product.werbung) != 1 &&
                                        product?.product_state?.trim() ==
                                            'I' && <Labels label="online" />}

                                    {!product?.price_tag?.includes('40') &&
                                    Number(product.werbung) != 1 &&
                                    (product?.product_state?.trim() == '9' ||
                                        product?.product_state?.trim() == 'L' ||
                                        product?.product_state?.trim() == '8' ||
                                        product?.product_state?.trim() ==
                                            'S') ? (
                                        <Labels label="sale" />
                                    ) : null}
                                </span>
                                {isFastDelivery ? (
                                    <span className="text-xs font-semibold text-skin-green block text-center lg:text-base min-h-[20px] lg:min-h-[24px]">
                                        <span className="text-sm">
                                            {isFastDelivery}
                                        </span>
                                    </span>
                                ) : null}
                                {/*TODO to make it not static*/}
                                {/* <Price {...product.price_range} /> */}
                            </div>
                            <div className="flex justify-between flex-row items-end w-full mt-auto px-4">
                                <div>
                                    <Variants
                                        isRelatedColor={isRelatedColor}
                                        isRelatedSize={isRelatedSize}
                                    />
                                </div>
                                {product && product.price_range ? (
                                    <Price
                                        priceRange={product.price_range}
                                        discountClass="text-xs text-skin-muted flex md:text-base font-normal line-through"
                                        priceClass="flex flex-col items-end text-base text-skin-muted text-skin-secondary font-bold md:text-xl"
                                        priceTag={
                                            product?.price_tag?.includes('U1')
                                                ? 'UVP*'
                                                : null
                                        }
                                    />
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <AddToWishListButton
                        product={product}
                        isTrashIcon={false}
                    />
                    {showAddToCartButton ? (
                        <div className="mt-4">
                            <AddToCartSection
                                sku={product.sku}
                                wishListCustomerView={wishListCustomerView}
                                product={product}
                            />
                        </div>
                    ) : null}
                </a>
            </Link>
        </div>
    );
};

export default ProductCard;
