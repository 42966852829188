const isSignedIn = () =>
    typeof window !== 'undefined' && localStorage.getItem('signin_token')
        ? true
        : false;
const token = () =>
    typeof window !== 'undefined'
        ? localStorage.getItem('signin_token')
        : undefined;

const initialState = {
    currentUser: null,
    getDetailsError: null,
    isGettingDetails: false,
    isResettingPassword: false,
    isSignedIn: isSignedIn(),
    resetPasswordError: null,
    token: token(),
    signOutLoading: false
};

const user = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USER_DETAILS':
            return {
                ...state,
                currentUser: action.payload,
                isSignedIn: isSignedIn(),
                token: token()
            };
        case 'LOADING_USER_DETAILS':
            return {
                ...state,
                isGettingDetails: action.payload,
                isSignedIn: isSignedIn(),
                token: token()
            };
        case 'ERROR_USER_DETAILS':
            return {
                ...state,
                getDetailsError: action.payload
            };
        case 'SIGN_OUT':
            localStorage.removeItem('signin_token');
            action.payload.signOut();

            return {
                ...state,
                token: undefined
            };
        case 'SET_LOADING_SIGN_OUT':
            return {
                ...state,
                signOutLoading: action.payload
            };
        case 'RESET_USER':
            return {
                ...state,
                currentUser: {},
                isSignedIn: false
            };
        default:
            return state;
    }
};

export default user;
