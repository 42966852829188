interface LoadingContentProps {
    tailWindCssString: string;
    aspectRatio?: string;
    children?: React.ReactNode;
}

const LoadingContent = (props: LoadingContentProps) => {
    const { tailWindCssString, aspectRatio, children } = props;

    return (
        <span
            className={
                tailWindCssString +
                ' ' +
                ' rounded-md bg-skin-site-darker-bg animate-pulse block opacity-80'
            }
            style={{ aspectRatio: aspectRatio }}
        >
            {children}
        </span>
    );
};

export default LoadingContent;
