import Link from 'next/link';
import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Popover, Tab, Transition } from '@headlessui/react';
import Image from 'next/image';
import {
    MenuIcon,
    SearchIcon,
    ShoppingBagIcon,
    LoginIcon,
    LogoutIcon,
    ChevronRightIcon,
    ArrowLeftIcon
} from '@heroicons/react/outline';
import CloseIcon from '../../images/close.svg';

const MobileNavigation = (props) => {
    const {
        categories,
        categoryUrlSuffix,
        toggleCart,
        toggleNavi,
        isSignedIn,
        drawer,
        isOpen,
        staticItems,
        featuredCategories,
        handledSignOut
    } = props;

    const [menuData, setMenuData] = useState([]);
    const [level, setLevel] = useState(1);
    const activeCategory = menuData[menuData?.length - 1];

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ');
    };

    const renderTitle = () => {
        switch (level) {
            case 1:
                return 'Menü';
            default:
                return activeCategory.name;
        }
    };

    const categories_copy = categories
        ? JSON.parse(JSON.stringify(categories))
        : [];

    const sortedCategories =
        categories_copy &&
        categories_copy.sort((a, b) => (a.position < b.position ? -1 : 1));

    const goBack = () => {
        const handledMenuItem = menuData.filter(
            (f) => f.id !== activeCategory.id
        );
        setLevel((prevState) => (prevState -= 1));
        setMenuData(handledMenuItem);
    };

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 flex z-40 lg:hidden"
                onClose={toggleNavi}
            >
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                >
                    <div className="relative w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto sm:max-w-sm">
                        <div className="flex justify-center items-center">
                            {activeCategory && level > 1 && (
                                <ArrowLeftIcon
                                    className="w-14 h-14 p-4 cursor-pointer"
                                    onClick={goBack}
                                />
                            )}
                            <h3
                                className={`text-lg ${
                                    activeCategory && level > 1
                                        ? 'ml-0 cursor-pointer'
                                        : 'ml-4'
                                } mr-auto`}
                                onClick={level > 1 ? goBack : null}
                            >
                                {renderTitle()}
                            </h3>
                            <button className="p-4" onClick={toggleNavi}>
                                <span className="sr-only">Close menu</span>
                                <CloseIcon />
                            </button>
                        </div>

                        {sortedCategories &&
                        (!activeCategory || activeCategory.length == 0) ? (
                            <ul className="mb-4">
                                {sortedCategories.map((category) => {
                                    {
                                        return category.include_in_menu ? (
                                            <li
                                                className="flex items-center justify-between border-b border-solid text-base cursor-pointer last:border-none"
                                                key={category.id}
                                            >
                                                {category.children.length >
                                                0 ? (
                                                    <>
                                                        <div
                                                            className="flex justify-between items-center w-full pl-4"
                                                            onClick={() => {
                                                                const category_copy =
                                                                    category.children
                                                                        ? JSON.parse(
                                                                              JSON.stringify(
                                                                                  category.children
                                                                              )
                                                                          )
                                                                        : [];

                                                                const sortedCategory =
                                                                    category_copy &&
                                                                    category_copy.sort(
                                                                        (
                                                                            a,
                                                                            b
                                                                        ) =>
                                                                            a.position <
                                                                            b.position
                                                                                ? -1
                                                                                : 1
                                                                    );
                                                                setLevel(
                                                                    level + 1
                                                                );
                                                                setMenuData([
                                                                    ...menuData,
                                                                    {
                                                                        ...category,
                                                                        children:
                                                                            sortedCategory
                                                                    }
                                                                ]);
                                                            }}
                                                        >
                                                            {category.name}
                                                            <ChevronRightIcon className="h-14 w-14 p-4" />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <Link
                                                        key={category.id}
                                                        passHref
                                                        href={
                                                            '/' +
                                                            category.url_path +
                                                            categoryUrlSuffix
                                                        }
                                                    >
                                                        <div
                                                            className="flex justify-between w-full p-4 border-b border-solid text-base last:border-none cursor-pointer"
                                                            onClick={() => {
                                                                toggleNavi();
                                                                localStorage.removeItem(
                                                                    'categoryScrolledIndex'
                                                                );
                                                            }}
                                                        >
                                                            {category.name}
                                                        </div>
                                                    </Link>
                                                )}
                                            </li>
                                        ) : null;
                                    }
                                })}
                            </ul>
                        ) : activeCategory?.children ? (
                            <ul className="mb-4">
                                <Link
                                    passHref
                                    href={
                                        '/' +
                                        activeCategory.url_path +
                                        categoryUrlSuffix
                                    }
                                >
                                    <li className="flex items-center justify-between border-b border-solid text-base cursor-pointer font-bold last:border-none">
                                        <div
                                            className="flex justify-between items-center w-full pl-4"
                                            onClick={() => {
                                                toggleNavi();
                                                localStorage.removeItem(
                                                    'categoryScrolledIndex'
                                                );
                                            }}
                                        >
                                            Alle {activeCategory.name}
                                            <ChevronRightIcon className="h-14 w-14 p-4" />
                                        </div>
                                    </li>
                                </Link>
                                {activeCategory?.children.map((category) => {
                                    {
                                        return category.include_in_menu ? (
                                            <li
                                                className="flex items-center justify-between border-b border-solid text-base cursor-pointer last:border-none"
                                                key={category.id}
                                            >
                                                {category?.children?.length >
                                                0 ? (
                                                    <>
                                                        <div
                                                            className="flex justify-between items-center w-full pl-4"
                                                            onClick={() => {
                                                                const category_copy =
                                                                    category.children
                                                                        ? JSON.parse(
                                                                              JSON.stringify(
                                                                                  category.children
                                                                              )
                                                                          )
                                                                        : [];

                                                                const sortedCategory =
                                                                    category_copy &&
                                                                    category_copy.sort(
                                                                        (
                                                                            a,
                                                                            b
                                                                        ) =>
                                                                            a.position <
                                                                            b.position
                                                                                ? -1
                                                                                : 1
                                                                    );
                                                                setLevel(
                                                                    level + 1
                                                                );
                                                                setMenuData([
                                                                    ...menuData,
                                                                    {
                                                                        ...category,
                                                                        children:
                                                                            sortedCategory
                                                                    }
                                                                ]);
                                                            }}
                                                        >
                                                            {category.name}
                                                            <ChevronRightIcon className="h-14 w-14 p-4" />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <Link
                                                        key={category.id}
                                                        passHref
                                                        href={
                                                            '/' +
                                                            category.url_path +
                                                            categoryUrlSuffix
                                                        }
                                                    >
                                                        <div
                                                            className="flex justify-between w-full p-4 border-b border-solid text-base last:border-none cursor-pointer"
                                                            onClick={() => {
                                                                toggleNavi();
                                                                localStorage.removeItem(
                                                                    'categoryScrolledIndex'
                                                                );
                                                            }}
                                                        >
                                                            {category.name}
                                                        </div>
                                                    </Link>
                                                )}
                                            </li>
                                        ) : null;
                                    }
                                })}
                            </ul>
                        ) : null}

                        <h3 className="pl-4">Kundencenter</h3>
                        <ul>
                            {isSignedIn ? (
                                <li
                                    className="flex justify-between p-4 border-b border-solid text-base cursor-pointer"
                                    onClick={() => {
                                        if (isSignedIn) {
                                            handledSignOut();
                                        }

                                        toggleNavi();
                                    }}
                                >
                                    Abmelden
                                </li>
                            ) : (
                                <Link passHref href={'/login'}>
                                    <li
                                        className="flex justify-between p-4 text-base cursor-pointer"
                                        onClick={() => {
                                            toggleNavi();
                                        }}
                                    >
                                        Anmelden
                                    </li>
                                </Link>
                            )}
                            {isSignedIn && (
                                <Link passHref href={'/account/overview'}>
                                    <li
                                        className="flex justify-between p-4 border-b border-solid text-base cursor-pointer"
                                        onClick={toggleNavi}
                                    >
                                        Mein Konto
                                    </li>
                                </Link>
                            )}
                        </ul>
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition.Root>
    );
};

export default MobileNavigation;
