export const addToWishlist = (payload) => {
    return {
        type: 'ADD_TO_WISHLIST',
        payload
    };
};

export const removeFromWishlist = (payload) => {
    return {
        type: 'REMOVE_FROM_WISHLIST',
        payload
    };
};

export const updateWishlist = (payload) => {
    return {
        type: 'UPDATE_WISHLIST',
        payload
    };
};

export const setWishlistMode = (payload) => {
    return {
        type: 'SET_WISHLIST_MODE',
        payload
    };
};

export const resetWishlist = () => {
    return {
        type: 'RESET_WISHLIST'
    };
};

export const mergeWishlists = (payload) => {
    return {
        type: 'MERGE_WISHLISTS',
        payload
    };
};