import { connect } from 'react-redux';
import { setCookiesPolicies, setInitialCookiesPolicies } from '../../store/actions/app';


const CookiesSetState = ({ setCookiesPoliciesState, setInitialCookiesPoliciesState }) => {
    function initCmp() {
        const consentData = __cmp('getCMPData');
        setInitialCookiesPoliciesState(consentData && consentData.vendorConsents ? consentData.vendorConsents : null)
    }//eslint-disable-line

    function pushUp() {
        const consentData = __cmp('getCMPData');
        if (consentData && consentData.vendorConsents) {
            setCookiesPoliciesState(consentData && consentData.vendorConsents ? consentData.vendorConsents : null)
        } else {
            setCookiesPoliciesState(null)
        }
    }

    __cmp("addEventListener", "settings", initCmp, false)
    __cmp("addEventListener", ["consent", pushUp, false], null)
    return <span />;
}

const mapDispatchToProps = dispatch => {
    return {
        setCookiesPoliciesState: value => dispatch(setCookiesPolicies(value)),
        setInitialCookiesPoliciesState: value => dispatch(setInitialCookiesPolicies(value))
    }
}

export default connect(null, mapDispatchToProps)(CookiesSetState);