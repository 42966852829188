import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import dropshipTime from '../../queries/dropshipTime.graphql';

const DropshipTime = (props) => {
    const { sku, rootClass, hideNoStockInfo } = props;
    const handledRootClass = rootClass ? rootClass : '';

    const [getDropShipTime, { loading, data, error }] = useLazyQuery(
        dropshipTime,
        {
            variables: { sku: sku }
        }
    );

    useEffect(() => {
        getDropShipTime({ variables: { sku: sku } });
    }, [sku]);

    if (error) {
        return <span />;
    }

    if (loading) {
        return <span />;
    }

    const stock = data ? data.dropshipTime.stock : null;
    const defaultDeliveryTime = data ? data.dropshipTime.deliverytime : null;

    return stock && stock >= 3 ? (
        <span className={handledRootClass}>
            <span className="text-skin-green">Sofort Verfügbar</span>
        </span>
    ) : stock && stock < 3 ? (
        <span className={handledRootClass}>
            <span className="text-skin-orange">Wenige verfügbar</span>
        </span>
    ) : !stock && defaultDeliveryTime && !hideNoStockInfo ? (
        <span className={handledRootClass}>
            {defaultDeliveryTime <= 14 && defaultDeliveryTime >= 7 ? (
                <span className="text-skin-green">
                    Auf Lager - Innerhalb 3 Wochen
                </span>
            ) : defaultDeliveryTime < 7 ? (
                <span className="text-skin-green">
                    Auf Lager - Innerhalb 2 Wochen
                </span>
            ) : (
                <span className="text-skin-orange">
                    {defaultDeliveryTime >= 15 && defaultDeliveryTime <= 21
                        ? 'Innerhalb 4 Wochen'
                        : defaultDeliveryTime > 21
                        ? 'Mehr als 4 Wochen'
                        : null}
                </span>
            )}
        </span>
    ) : null;
};
export default DropshipTime;
