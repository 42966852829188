import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';

interface ErrorHandlerProps {
    message: string;
    icon: boolean;
    classes?: string;
}

const ErrorMessage = (props: ErrorHandlerProps) => {
    const { message, icon, classes } = props;

    return (
        <div
            className={`flex my-4 p-3 bg-red-50 text-red-600 border border-solid border-red-600 ${
                classes ? classes : ''
            }`}
        >
            {icon && <ExclamationCircleIcon className="h-6 w-6 mr-2" />}
            {message}
        </div>
    );
};

export default ErrorMessage;
