const initialState = {
    allMethods: {},
    updateShippingMethods: false
};

const availableShippingMethods = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'SET_AVAILABLE_SHIPPING_METHODS_STATE':
            return {
                ...state,
                allMethods: payload
            };
        case 'SET_UPDATE_SHIPPING_METHODS':
            return {
                ...state,
                updateShippingMethods: payload
            };
        default:
            return state;
    }
};

export default availableShippingMethods;
