import { useEffect } from 'react';
import GET_CART_DETAILS from '../../queries/getCartDetails.graphql';
import { connect } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { createCartDetails, createCart } from '../../store/actions/cart';
import CREATE_CART_MUTATION from '../MiniCart/createCart.graphql';

const GetCartDetails = ({
    cartId,
    createCart
}: {
    cartId: string;
    createCart: Function;
}) => {
    const dispatch = useDispatch();
    const [createCartMutation] = useMutation(CREATE_CART_MUTATION, {
        onCompleted: (data) => {
            createCart({
                cartId: data?.cartId,
                addItemError: data?.addItemError
            });
        }
    });
    const [refreshCart] = useLazyQuery(GET_CART_DETAILS, {
        variables: {
            cartId
        },
        onCompleted: (data) => {
            dispatch(createCartDetails(data.cart));
        },
        onError: () => {
            createCartMutation();
        }
    });

    useEffect(() => {
        if (cartId) {
            refreshCart();
        }
    }, []);

    return null;
};

const mapStateToProps = ({
    cart: { cartId }
}: {
    cart: { cartId: string };
}) => {
    return {
        cartId
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        createCart: (payload: object) => dispatch(createCart(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GetCartDetails);
