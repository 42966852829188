import { useMemo } from 'react';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { concatPagination } from '@apollo/client/utilities';

/**
 * Polyfill Global Variables in Server
 */
if (!process.browser) {
    global.URL = require('url').URL;
}

let apolloClient;

function createApolloClient(token) {
    const uri = process.browser
        ? new URL('/graphql', location.href)
        : new URL('/graphql', process.env.MAGENTO_URL).href;

    return new ApolloClient({
        ssrMode: !process.browser,
        credentials: 'include',
        link: new HttpLink({
            uri,
            credentials: 'include', // Additional fetch() options like `credentials` or `headers`,
            headers: {
                // We are adding the Store View code here to be passed as a header
                authorization: token ? `Bearer ${token}` : '',
                Store: 'funkelhaus'
            }
        }),
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        allPosts: concatPagination()
                    }
                }
            }
        })
    });
}

export function initializeApollo(initialState = null, token) {
    const _apolloClient =
        apolloClient == null || token
            ? createApolloClient(token)
            : apolloClient;

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = _apolloClient.extract();
        // Restore the cache using the data passed from getStaticProps/getServerSideProps
        // combined with the existing cached data
        _apolloClient.cache.restore({ ...existingCache, ...initialState });
    }
    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') return _apolloClient;
    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = _apolloClient;

    return _apolloClient;
}

export function useApollo(initialState, token) {
    const store = useMemo(
        () => initializeApollo(initialState, token),
        [initialState]
    );
    return store;
}
