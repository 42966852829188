import { useMutation } from '@apollo/client';
import ADD_TO_WISH_LIST from '../../queries/addProductsToWishlist.graphql';
import REMOVE_FROM_WISH_LIST from '../../queries/removeProductsFromWishlist.graphql';
import { useDispatch } from 'react-redux';
import {
    addToWishlist,
    removeFromWishlist,
    updateWishlist
} from '../../store/actions/wishlist';

export const useWishlist = ({
    customerWishListId,
    item,
    itemId,
    isGuestWishlist
}) => {
    const dispatch = useDispatch();
    const handleAddToWishlist = (payload) => dispatch(addToWishlist(payload));
    const handleRemoveFromWishlist = (payload) =>
        dispatch(removeFromWishlist(payload));

    const [addToWishList, { data, loading, error }] = useMutation(
        ADD_TO_WISH_LIST,
        {
            onCompleted: (data) =>
                handleAddToWishlist(data.addProductsToWishlist)
        }
    );
    const [
        removeFromWishList,
        { data: removeData, loading: removeLoading, error: removeError }
    ] = useMutation(REMOVE_FROM_WISH_LIST, {
        onCompleted: (data) =>
            handleRemoveFromWishlist(data.removeProductsFromWishlist)
    });

    const handleGuestWishlist = (value, mode) => {
        localStorage.setItem('guestWishlist', JSON.stringify(value));
        const handledValues = {
            wishlist: {
                items: value
            }
        };
        if (mode === 'adding') {
            handleAddToWishlist(handledValues);
        } else {
            handleRemoveFromWishlist(handledValues);
        }
    };

    const addProductToWishList = () => {
        const existingGuestWishlist = JSON.parse(
            localStorage.getItem('guestWishlist')
        )
            ? JSON.parse(localStorage.getItem('guestWishlist'))
            : [];

        Array.isArray(existingGuestWishlist) &&
        !existingGuestWishlist.includes(item.sku)
            ? existingGuestWishlist.push(item.sku)
            : null;

        if (isGuestWishlist) {
            if (existingGuestWishlist) {
                handleGuestWishlist(existingGuestWishlist, 'adding');
            } else {
                handleGuestWishlist([item.sku], 'adding');
            }
        } else {
            addToWishList({
                variables: {
                    wishlistId: customerWishListId,
                    wishlistItems: [item]
                }
            });
        }
    };

    const removeProductFromWishList = () => {
        const existingGuestWishlist = JSON.parse(
            localStorage.getItem('guestWishlist')
        )
            ? JSON.parse(localStorage.getItem('guestWishlist'))
            : [];
        const guestWishlistItemsUpdated =
            Array.isArray(existingGuestWishlist) &&
            existingGuestWishlist.includes(item.sku)
                ? existingGuestWishlist.filter((f) => f !== item.sku)
                : null;
        if (isGuestWishlist) {
            if (existingGuestWishlist) {
                handleGuestWishlist(guestWishlistItemsUpdated);
            } else {
                handleGuestWishlist([item.sku]);
            }
        } else {
            removeFromWishList({
                variables: {
                    wishlistId: customerWishListId,
                    wishlistItemsIds: [itemId]
                }
            });
        }
    };

    return {
        addProductToWishList,
        removeProductFromWishList,
        wishlistLoading: loading || removeLoading,
        wishlistError: error || removeError
    };
};
