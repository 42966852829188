import React, { Fragment, useState } from 'react';
import Link from 'next/link';
import { Dialog, Popover, Tab, Transition } from '@headlessui/react';
import TopBanner from '../TopBanner';
import Image from 'next/image';
import {
    MenuIcon,
    SearchIcon,
    ShoppingBagIcon,
    XIcon,
    ArrowCircleRightIcon
} from '@heroicons/react/outline';
import SaleIcon from '../../images/sale.svg';
import { isMobile } from 'react-device-detect';
import { browserNavigationBarTemplate } from '../LoadingContent/loadingTemplates';
import CmsBlock from '../CmsBlock/cmsBlock';
import { useRouter } from 'next/router';

const DesktopNavigation = (props) => {
    const [hoverCategory, setHoverCategory] = useState(null);
    const {
        categories,
        categoryUrlSuffix,
        staticItems,
        featuredCategories,
        loading
    } = props;

    const router = useRouter();

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ');
    };

    if (loading) {
        return browserNavigationBarTemplate;
    }

    // deep copy to avoid directly mutating state

    const categories_copy = categories
        ? JSON.parse(JSON.stringify(categories))
        : [];

    const sortedCategories =
        categories_copy &&
        categories_copy.sort((a, b) => (a.position < b.position ? -1 : 1));

    const checkForNumbersRegex = (url) => {
        // Url path has a number at the end most of the time, like 'kerzen-kerzenstaender-503', but sometimes it doesn't.
        // We check for this case in this function and omit it so that we can easily determine which url is active and apply the appropriate styles further down

        const patternWithNum = /^.*?(?=-\d)/;

        if (patternWithNum.test(url)) {
            // return string we have received but omit '-number' part with regex
            return patternWithNum.exec(url)[0];
        } else {
            // return string we have received but omit query part
            return url.split('?')[0];
        }
    };

    return (
        <Popover.Group className="hidden lg:flex justify-center w-full z-20">
            <div className="h-full w-full flex justify-between z-20">
                {sortedCategories
                    ? sortedCategories.map((category) =>
                          category.include_in_menu ? (
                              category.children.length > 0 ? (
                                  <Popover
                                      key={category.name}
                                      className="flex"
                                      onMouseEnter={() => {
                                          !isMobile &&
                                              setHoverCategory(category.name);
                                      }}
                                      onMouseLeave={() => {
                                          !isMobile && setHoverCategory(null);
                                      }}
                                      onClick={() =>
                                          !isMobile && setHoverCategory(null)
                                      }
                                  >
                                      {({ open, close }) => (
                                          <>
                                              {!isMobile ? (
                                                  <Link
                                                      passHref
                                                      href={
                                                          '/' +
                                                          category.url_path
                                                      }
                                                  >
                                                      <a className="flex">
                                                          <div className="relative flex">
                                                              <Popover.Button
                                                                  className={classNames(
                                                                      checkForNumbersRegex(
                                                                          router.asPath
                                                                              .split(
                                                                                  '/'
                                                                              )[1]
                                                                              .replace(
                                                                                  '.html',
                                                                                  ''
                                                                              )
                                                                      ) ==
                                                                          checkForNumbersRegex(
                                                                              category.url_path
                                                                          )
                                                                          ? 'text-skin-primary-hover border-transparent'
                                                                          : 'border-transparent text-skin-base',
                                                                      'relative z-10 flex items-center  py-2 transition-colors ease-out duration-200 text-base border-b-2 hover:text-skin-primary-hover font-semibold '
                                                                  )}
                                                              >
                                                                  {
                                                                      category.name
                                                                  }
                                                              </Popover.Button>
                                                          </div>
                                                      </a>
                                                  </Link>
                                              ) : (
                                                  <div className="relative flex">
                                                      <Popover.Button
                                                          className={classNames(
                                                              checkForNumbersRegex(
                                                                  router.asPath
                                                                      .split(
                                                                          '/'
                                                                      )[1]
                                                                      .replace(
                                                                          '.html',
                                                                          ''
                                                                      )
                                                              ) ==
                                                                  checkForNumbersRegex(
                                                                      category.url_path
                                                                  )
                                                                  ? 'text-skin-primary-hover border-transparent'
                                                                  : 'border-transparent text-skin-base',
                                                              'relative z-10 flex items-center  py-2 transition-colors ease-out duration-200 text-base border-b-2 hover:text-skin-primary-hover font-semibold '
                                                          )}
                                                      >
                                                          {category.name}
                                                      </Popover.Button>
                                                  </div>
                                              )}

                                              <Transition
                                                  show={
                                                      !isMobile
                                                          ? hoverCategory ==
                                                            category.name
                                                              ? true
                                                              : false
                                                          : open
                                                  }
                                                  as={Fragment}
                                                  enter="transition ease-out duration-200"
                                                  enterFrom="opacity-0"
                                                  enterTo="opacity-100"
                                                  leave="transition ease-in duration-150"
                                                  leaveFrom="opacity-100"
                                                  leaveTo="opacity-0"
                                              >
                                                  <Popover.Panel className="absolute top-full inset-x-0 text-md text-skin-muted  bg-white z-10 border border-solid border-skin-muted">
                                                      <div className="grid grid-cols-4 xl:grid-cols-5 gap-y-10 gap-x-8 py-6 px-12">
                                                          <div className="col-span-3 xl:col-span-4">
                                                              <div className="grid grid-cols-3 xl:grid-cols-4 gap-y-10 gap-x-8">
                                                                  {category.children
                                                                      .sort(
                                                                          (
                                                                              a,
                                                                              b
                                                                          ) =>
                                                                              a.position <
                                                                              b.position
                                                                                  ? -1
                                                                                  : 1
                                                                      )
                                                                      .slice()
                                                                      .map(
                                                                          (
                                                                              level2
                                                                          ) => (
                                                                              <div
                                                                                  key={
                                                                                      level2.name
                                                                                  }
                                                                              >
                                                                                  <Link
                                                                                      href={
                                                                                          '/' +
                                                                                          level2.url_path +
                                                                                          categoryUrlSuffix
                                                                                      }
                                                                                  >
                                                                                      <a
                                                                                          id={`${level2.name}-heading`}
                                                                                          className={`text-lg text-skin-base font-bold text-base text-skin-bolder-hover ${
                                                                                              checkForNumbersRegex(
                                                                                                  router.asPath
                                                                                                      .split(
                                                                                                          '/'
                                                                                                      )
                                                                                                      .pop()
                                                                                                      .replace(
                                                                                                          '.html',
                                                                                                          ''
                                                                                                      )
                                                                                              ) ==
                                                                                                  checkForNumbersRegex(
                                                                                                      level2.url_key
                                                                                                  ) &&
                                                                                              'underline'
                                                                                          }`}
                                                                                          onClick={
                                                                                              close
                                                                                          }
                                                                                      >
                                                                                          {
                                                                                              level2.name
                                                                                          }
                                                                                      </a>
                                                                                  </Link>
                                                                                  <ul
                                                                                      role="list"
                                                                                      aria-labelledby={`${level2.name}-heading`}
                                                                                      className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                                                                                  >
                                                                                      {level2.children
                                                                                          .sort(
                                                                                              (
                                                                                                  a,
                                                                                                  b
                                                                                              ) =>
                                                                                                  a.position <
                                                                                                  b.position
                                                                                                      ? -1
                                                                                                      : 1
                                                                                          )
                                                                                          .map(
                                                                                              (
                                                                                                  level3
                                                                                              ) => (
                                                                                                  <li
                                                                                                      key={
                                                                                                          level3.name
                                                                                                      }
                                                                                                      className="relative pl-6 flex items-center"
                                                                                                  >
                                                                                                      <Link
                                                                                                          href={{
                                                                                                              pathname:
                                                                                                                  '/[...pageUrl]',
                                                                                                              query: {
                                                                                                                  pageUrl:
                                                                                                                      encodeURIComponent(
                                                                                                                          level3.url_path +
                                                                                                                              categoryUrlSuffix
                                                                                                                      )
                                                                                                              }
                                                                                                          }}
                                                                                                          as={`/${
                                                                                                              level3.url_path +
                                                                                                              categoryUrlSuffix
                                                                                                          }`}
                                                                                                      >
                                                                                                          <a
                                                                                                              className={`text-sm leading-5 font-normal normal-case text-skin-base  ${
                                                                                                                  checkForNumbersRegex(
                                                                                                                      router.asPath
                                                                                                                          .split(
                                                                                                                              '/'
                                                                                                                          )
                                                                                                                          .pop()
                                                                                                                          .replace(
                                                                                                                              '.html',
                                                                                                                              ''
                                                                                                                          )
                                                                                                                  ) ==
                                                                                                                      checkForNumbersRegex(
                                                                                                                          level3.url_key
                                                                                                                      ) &&
                                                                                                                  'text-skin-bolder-hover'
                                                                                                              }`}
                                                                                                              onClick={
                                                                                                                  close
                                                                                                              }
                                                                                                          >
                                                                                                              <ArrowCircleRightIcon className="h-4 w-4 absolute top-0.5 left-0" />
                                                                                                              {
                                                                                                                  level3.name
                                                                                                              }
                                                                                                          </a>
                                                                                                      </Link>
                                                                                                  </li>
                                                                                              )
                                                                                          )}
                                                                                  </ul>
                                                                              </div>
                                                                          )
                                                                      )}
                                                              </div>
                                                          </div>
                                                          <div
                                                              className={
                                                                  'flex items-center'
                                                              }
                                                          >
                                                              <CmsBlock
                                                                  identifiers={[
                                                                      'category_trend_' +
                                                                          category.id
                                                                  ]}
                                                              />
                                                          </div>
                                                      </div>
                                                      <Link
                                                          passHref
                                                          href={`/${category.url_path}`}
                                                      >
                                                          <div
                                                              className="p-2 text-center text-skin-base font bg-skin-site-bg-alternate cursor-pointer"
                                                              onClick={close}
                                                          >
                                                              <ul>
                                                                  <li className="flex items-center justify-center font-bold">
                                                                      <ArrowCircleRightIcon className="h-4 w-4 mr-2" />{' '}
                                                                      Alle{' '}
                                                                      {
                                                                          category.name
                                                                      }
                                                                  </li>
                                                              </ul>
                                                          </div>
                                                      </Link>
                                                  </Popover.Panel>
                                              </Transition>
                                          </>
                                      )}
                                  </Popover>
                              ) : (
                                  <Link
                                      key={category.name}
                                      href={`/${category.url_path}`}
                                  >
                                      <a
                                          className={
                                              category.name == 'Sale'
                                                  ? 'flex items-center text-base uppercase  py-2 text-skin-secondary hover:text-skin-secondary font-semibold'
                                                  : 'flex items-center text-base font-semibold'
                                          }
                                      >
                                          {category.name == 'Sale' ? (
                                              <SaleIcon className="h-7 w-7 mr-2" />
                                          ) : null}
                                          {category.name}
                                      </a>
                                  </Link>
                              )
                          ) : null
                      )
                    : null}

                {staticItems.pages.map((page) => (
                    <Link key={page.name} href={page.href}>
                        <a
                            className="flex items-center text-base uppercase px-5 py-2 text-skin-base hover:text-skin-primary-hover hover:bg-skin-dirt-white-bg font-semibold"
                            onClick={close}
                        >
                            {page.name}
                        </a>
                    </Link>
                ))}
            </div>
        </Popover.Group>
    );
};

export default DesktopNavigation;
