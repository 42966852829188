import React from 'react';
import { XIcon } from '@heroicons/react/outline';
import { useCart } from '../../lib/useCart';

interface ProductUpdateProps {
    sku: string;
    productId: string;
    initialQuantity: string;
}

const ProductUpdate = (props: ProductUpdateProps) => {
    const { sku, productId, initialQuantity } = props;
    const quantity = initialQuantity ? initialQuantity : 1;

    const talonProps = useCart({
        quantity: quantity,
        sku: sku,
        productType: 'simple'
    });

    const {
        isAddingItem,
        updateCartLoading,
        handleUpdateCart,
        handleRemoveFromCart,
        updateCartError
    } = talonProps;

    if (updateCartError) {
        console.log('updateCartError: ', updateCartError.message);
    }

    const optionsItems = [];
    const qtyMaxValue = quantity <= 20 ? 20 : quantity;
    for (var i = 1; i <= qtyMaxValue; i++) {
        optionsItems.push(i);
    }

    return (
        <div className="flex-row flex">
            <div className="mb-2 relative block w-16 sm:mb-0 quantityArrow">
                <select
                    onChange={(e) => {
                        handleUpdateCart(e.target.value, productId);
                    }}
                    defaultValue={quantity}
                    className="border border-gray-300 text-base font-bold rounded-lg text-gray-600 h-10 pl-3 pr-6 bg-white hover:border-gray-400 focus:outline-none appearance-none mr-4 cursor-pointer"
                >
                    {optionsItems.map((item) => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </select>
            </div>
            <button
                className="absolute top-6 right-4"
                disabled={isAddingItem || updateCartLoading}
                type="button"
                onClick={() => handleRemoveFromCart(productId)}
            >
                <XIcon
                    className="block h-6 w-6 text-black font-bold"
                    aria-hidden="true"
                />
            </button>
        </div>
    );
};

export default ProductUpdate;
