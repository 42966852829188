import GET_PRODUCTS_BY_SKUS from '../../queries/getProductsBySkus.graphql';
import { useQuery } from '@apollo/client';
import ProductCard from '../ProductCard';
import Slider from 'react-slick';
import { homepageProductsLoadingTemplate } from '../LoadingContent/loadingTemplates';

interface ProductBlockProps {
    skus: string[];
}

const ProductBlock = ({ skus }: ProductBlockProps) => {
    const { data, loading, error } = useQuery(GET_PRODUCTS_BY_SKUS, {
        variables: {
            skus
        }
    });

    if (loading) return homepageProductsLoadingTemplate;
    if (error) {
        console.log(error);
        return <span />;
    }

    const settings = {
        dots: false,
        arrows: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
        infinite: false,
        className: 'topsellerCarousel',
        draggable: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: true,
                    arrows: false,
                    infinite: true,
                    centerMode: false,
                    speed: 500,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    className: 'activeDots topsellerCarousel',
                    draggble: true
                }
            },
            {
                breakpoint: 640,
                settings: {
                    dots: true,
                    arrows: false,
                    infinite: true,
                    centerMode: true,
                    speed: 500,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    className: 'activeDots topsellerCarousel',
                    draggble: true
                }
            },
            {
                breakpoint: 520,
                settings: {
                    dots: true,
                    arrows: false,
                    infinite: true,
                    centerMode: true,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    className: 'activeDots topsellerCarousel',
                    draggble: true
                }
            }
        ]
    };

    return data ? (
        <div>
            <Slider {...settings}>
                {data.products.items.map((product: { id: string }) => (
                    <div
                        key={product.id}
                        className="px-3 product-block-container"
                    >
                        <div className="bg-white">
                            <ProductCard
                                showAddToCartButton={false}
                                productUrlSuffix=""
                                product={product}
                                hideNoStockInfo={true}
                            />
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    ) : null;
};

export default ProductBlock;
