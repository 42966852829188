import React, { useState } from 'react';
import AddToCartButton from './AddToCartButton';

interface AddToCartSectionTypes {
    sku: string;
    product: {
        sku: string;
        name: string;
        mhs_stock: string;
        product_state: string;
        price_range: {
            minimum_price: {
                final_price: {
                    value: number;
                };
                discount: {
                    amount_off: number;
                };
            };
        };
    };
    wishListCustomerView: boolean;
    getQuantity?: Function;
}

const AddToCartSection = ({
    product,
    sku,
    wishListCustomerView,
    getQuantity
}: AddToCartSectionTypes) => {
    const [qty, setQty] = useState(1);
    const handleQtyChange = (event: any) => {
        setQty(event.target.value);
        getQuantity ? getQuantity(event.target.value) : null;
    };

    return (
        <div
            className="flex justify-between items-center flex-row"
            onClick={(e) => e.preventDefault()}
        >
            <div className="relative block w-20 sm:mb-0 mr-4 quantityArrow">
                <select
                    onChange={(e) => handleQtyChange(e)}
                    className="border border-gray-300 text-lg font-bold rounded-lg text-gray-600 h-14 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none mr-4 cursor-pointer"
                >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
                </select>
            </div>
            <AddToCartButton
                product={product}
                sku={sku}
                quantity={qty}
                wishListCustomerView={wishListCustomerView}
            />
        </div>
    );
};

export default AddToCartSection;
