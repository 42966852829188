export const setAvailableShippingMethodsState = (payload) => {
    return {
        type: 'SET_AVAILABLE_SHIPPING_METHODS_STATE',
        payload
    };
};
export const setUpdateShippingMethods = (payload) => {
    return {
        type: 'SET_UPDATE_SHIPPING_METHODS',
        payload
    };
};
