import React, { useState, useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/solid';

const SeoText = ({
    children
}: {
    id: string;
    children: React.ReactChildren;
}) => {
    const [readMore, setReadMore] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);

        return () => setIsMounted(false);
    }, []);

    return isMounted &&
        isMobile &&
        containerRef &&
        containerRef.current &&
        containerRef!.current!.offsetHeight >= 300 ? (
        <div ref={containerRef}>
            <div
                className={`${
                    readMore == false
                        ? 'relative max-h-[400px] overflow-hidden'
                        : ''
                }`}
            >
                {children}
                {!readMore && (
                    <div className="absolute bottom-0 w-full h-full bg-gradient-to-t via-transparent from-white" />
                )}
            </div>
            <button
                onClick={() => {
                    readMore == false ? setReadMore(true) : setReadMore(false);
                }}
                className={'readMoreBtn'}
            >
                {!readMore ? (
                    <>
                        weiterlesen
                        <ChevronRightIcon className="w-6 h-6" />
                    </>
                ) : (
                    <>
                        weniger anzeigen
                        <ChevronDownIcon className="w-6 h-6" />
                    </>
                )}
            </button>
        </div>
    ) : (
        <div ref={containerRef}>{children}</div>
    );
};

export default SeoText;
