import Button from '../Button';
import { useCart } from '../../lib/useCart';
import { toggleDrawer } from '../../store/actions/app';
import { connect } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { useRouter } from 'next/router';

interface AddToCartButtonPropsTypes {
    quantity: number;
    sku: string;
    product: {
        sku: string;
        name: string;
        mhs_stock: string;
        product_state: string;
        price_range: {
            minimum_price: {
                final_price: {
                    value: number;
                };
                discount: {
                    amount_off: number;
                };
            };
        };
    };
    wishListCustomerView: boolean;
}

const AddToCartButton = (props: AddToCartButtonPropsTypes) => {
    const { quantity, sku, wishListCustomerView, product } = props;

    const router = useRouter();

    const mhsStock = product.mhs_stock;
    const mhsObj = mhsStock ? JSON.parse(mhsStock)?.store_stock : null;
    const productState = product?.product_state;
    const onlineStock =
        mhsObj && mhsObj['online'] ? mhsObj['online'].stock : null;

    const isNotAvailable =
        (productState == '8' ||
            productState == 'L' ||
            productState == '9' ||
            productState == 'S' ||
            productState == 'N' ||
            productState == '4') &&
        onlineStock <= 0
            ? true
            : false;

    const mainDataLayer = {
        currency: 'EUR',
        value: product.price_range.minimum_price.final_price.value * quantity,
        items: [
            {
                item_id: product.sku,
                item_name: product.name,
                currency: 'EUR',
                price: product.price_range.minimum_price.final_price.value,
                discount: product.price_range.minimum_price.discount.amount_off
                    ? product.price_range.minimum_price.discount.amount_off
                    : 0,
                quantity: quantity
            }
        ]
    };

    const talonProps = useCart({
        quantity: quantity,
        sku: sku,
        productType: 'simple',
        mainDataLayer
    });

    const handleToggleCart = useDebouncedCallback(
        // function
        () => {
            router.push('/cart');
        },
        // delay in ms
        1000
        // { trailing: false }
    );

    const { isAddingItem, isUpdatingItem, handleAddToCart } = talonProps;

    return (
        <Button
            className={'w-full px-2 h-14 font-semibold'}
            disabled={
                isAddingItem || isUpdatingItem || isNotAvailable ? true : false
            }
            onClick={async () => {
                await handleAddToCart();
                await handleToggleCart();
                await window.scrollTo(0, 0);
            }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="39.724"
                height="30"
                viewBox="0 0 39.724 35"
            >
                <g id="cart_icon-g1P" transform="translate(0 2.29)">
                    <path
                        id="Path_11"
                        data-name="Path 11"
                        d="M352.224,385.148a3.353,3.353,0,1,0,3.353,3.353A3.353,3.353,0,0,0,352.224,385.148Zm0,5.2a1.844,1.844,0,1,1,1.311-.539,1.852,1.852,0,0,1-1.311.539Zm0,0"
                        transform="translate(-323.543 -359.144)"
                        fill="#ffffff"
                    />
                    <path
                        id="Path_12"
                        data-name="Path 12"
                        d="M38.842,3.573H7.764L7.217,1.211a4.376,4.376,0,0,0-4.209-3.5H.876A.9.9,0,0,0,0-1.359a.9.9,0,0,0,.876.931H3.008A2.6,2.6,0,0,1,5.516,1.647L9.768,20.1a4.359,4.359,0,0,0,4.21,3.482H32.17a4.36,4.36,0,0,0,4.23-3.558L39.7,4.689a.948.948,0,0,0-.169-.776A.846.846,0,0,0,38.842,3.573Zm-4.154,16.04a2.584,2.584,0,0,1-2.518,2.107H13.979a2.588,2.588,0,0,1-2.508-2.066L8.192,5.434h29.55Zm0,0"
                        transform="translate(0 0)"
                        fill="#ffffff"
                    />
                    <path
                        id="Path_13"
                        data-name="Path 13"
                        d="M183.33,385.148a3.353,3.353,0,1,0,3.353,3.353A3.353,3.353,0,0,0,183.33,385.148Zm0,5.2a1.844,1.844,0,1,1,1.311-.539,1.852,1.852,0,0,1-1.311.539Zm0,0"
                        transform="translate(-166.91 -359.144)"
                        fill="#ffffff"
                    />
                </g>
            </svg>

            <span
                className={
                    wishListCustomerView
                        ? 'ml-3 block xs:hidden lg:block text-base xs:text-xs xl:text-base'
                        : 'ml-3 text-base'
                }
            >
                In den Warenkorb
            </span>
        </Button>
    );
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        toggleCart: () => dispatch(toggleDrawer('cart'))
    };
};

export default connect(null, mapDispatchToProps)(AddToCartButton);
