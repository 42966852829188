import React from 'react';

export const Price = (props) => {
    const { priceRange, showSaved, quantity, priceTag } = props;
    const priceClass = props.priceClass || '';
    const handledQuantity = quantity != undefined ? Number(quantity) : 1;
    const amountOffClass = props.amountOffClass || 'text-base';
    const discountClass = props.discountClass || '';
    const currency =
        priceRange?.minimum_price?.regular_price?.currency || 'EUR';
    const price = (priceRange?.minimum_price?.final_price.value).toLocaleString(
        'de-DE',
        {
            style: 'currency',
            currency
        }
    );

    const amountOff = priceRange?.minimum_price?.discount.amount_off
        ? priceRange?.minimum_price?.discount.amount_off
        : null;
    const discountOff = priceRange?.minimum_price?.discount.amount_off
        ? priceRange.minimum_price.discount.amount_off.toLocaleString('de-DE', {
              style: 'currency',
              currency
          })
        : null;
    // const discountPercent = priceRange?.minimum_price?.discount.percent_off
    //     ? Math.round(priceRange?.minimum_price.discount.percent_off)
    //     : null;
    return (
        <span className={priceClass}>
            {discountOff ? (
                <span className="flex justify-end items-end flex-col xs:flex-row xs:justify-start xs:items-center">
                    {priceTag ? (
                        <span className="text-xs text-skin-muted flex md:text-base font-normal ml-2 mr-3 xs:mr-2 xs:ml-0">
                            {priceTag}
                        </span>
                    ) : null}
                    <span className={discountClass}>
                        {priceRange.minimum_price.regular_price?.value.toLocaleString(
                            'de-DE',
                            {
                                style: 'currency',
                                currency
                            }
                        )}
                    </span>
                </span>
            ) : null}
            <span className="text-skin-red">{price}</span>
            {amountOff && showSaved ? (
                <span className={amountOffClass}>
                    Sie sparen{' '}
                    {amountOff.toLocaleString('de-DE', {
                        style: 'currency',
                        currency
                    })}
                </span>
            ) : null}
        </span>
    );
};
