import { connect } from 'react-redux';

const WishlistCount = ({
    wishlistCount,
    mobileView
}: {
    wishlistCount: number;
    mobileView?: boolean;
}) => {
    return wishlistCount &&
        typeof wishlistCount == 'number' &&
        wishlistCount > 0 ? (
        <span className={mobileView ? 'counter-wishlist' : 'counter-desktop'}>
            {wishlistCount} {!mobileView ? 'Artikel' : ''}
        </span>
    ) : !mobileView ? (
        <span className={mobileView ? 'counter-wishlist' : ''}>
            {'0 Artikel'}
        </span>
    ) : null;
};

const mapStateToProps = ({
    wishlist
}: {
    wishlist: { wishlistData: { wishlist: { items: [] } } };
}) => {
    const wishlistCount: number =
        wishlist?.wishlistData?.wishlist?.items?.length;
    return {
        wishlistCount
    };
};

export default connect(mapStateToProps)(WishlistCount);
