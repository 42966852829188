import React, { useEffect } from 'react';
import Link from 'next/link';
import { ChevronRightIcon } from '@heroicons/react/outline';
import Button from '../Button';

const LoginPopup = ({
    isSignedIn,
    handledSignOut,
    className,
    setLoginPopupOpen,
    loginPopupOpen
}: {
    isSignedIn: boolean;
    handledSignOut: () => Promise<void>;
    className?: string;
    setLoginPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
    loginPopupOpen: boolean;
}) => {
    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (
                loginPopupOpen &&
                (e.target as HTMLElement).id !== 'loginPopup'
            ) {
                setLoginPopupOpen(false);
            }
        };

        document.body.addEventListener('click', handleClick);

        return () => {
            document.body.removeEventListener('click', handleClick);
        };
    }, [setLoginPopupOpen, loginPopupOpen]);

    return (
        <div
            id="loginPopup"
            className={`${
                loginPopupOpen ? 'visible opacity-100' : 'invisible opacity-0'
            } absolute top-12 left-1/2 -translate-x-1/2 w-72 p-4 bg-white z-30 rounded-md drop-shadow-[0_0_5px_rgba(0,0,0,0.2)] before:content-[''] before:absolute before:top-0 before:left-1/2 before:-translate-y-1/2 before:-translate-x-1/2 before:w-[20px] before:h-[20px] before:rotate-45 before:bg-white transition-all duration-200 group-hover:visible group-hover:opacity-100 ${className}`}
            onClick={(e) => e.stopPropagation()}
        >
            {!isSignedIn ? (
                <Link passHref href="/login">
                    <Button
                        className="w-full mb-2 my-0"
                        isNextLink
                        onClick={() => {
                            setLoginPopupOpen(false);
                        }}
                    >
                        Anmelden
                    </Button>
                </Link>
            ) : (
                <Button
                    className="w-full mb-2 my-0"
                    onClick={() => {
                        handledSignOut();
                        setLoginPopupOpen(false);
                    }}
                >
                    Abmelden
                </Button>
            )}
            {!isSignedIn && (
                <Link href="/register">
                    <a
                        onClick={() => {
                            setLoginPopupOpen(false);
                        }}
                        className="text-sm"
                    >
                        Neuer Kunde? Jetzt registrieren
                    </a>
                </Link>
            )}
            <ul className="mt-2 space-y-1">
                <li className="flex items-center">
                    <ChevronRightIcon className="h-5 w-5 text-skin-bolder-hover" />
                    <Link
                        passHref
                        href={isSignedIn ? '/account/overview' : '/login'}
                    >
                        <a
                            onClick={() => {
                                setLoginPopupOpen(false);
                            }}
                            className="text-sm"
                        >
                            Meine Übersicht
                        </a>
                    </Link>
                </li>
                <li className="flex items-center">
                    <ChevronRightIcon className="h-5 w-5 text-skin-bolder-hover" />
                    <Link
                        passHref
                        href={isSignedIn ? '/account/orders' : '/login'}
                    >
                        <a
                            onClick={() => {
                                setLoginPopupOpen(false);
                            }}
                            className="text-sm"
                        >
                            Meine Bestellungen
                        </a>
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default LoginPopup;
