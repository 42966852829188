import React, { MouseEventHandler, ForwardedRef } from 'react';

export const Button = React.forwardRef(
    (props: ButtonProps, ref: ForwardedRef<HTMLAnchorElement>) => {
        const { isNextLink, isSecondary, className: classes, ...rest } = props;

        const buttonClass = `flex items-center justify-center py-2 px-4 text-sm font-bold rounded-md text-white bg-skin-blue-bg hover:bg-skin-primary-hover hover:text-white focus:outline-none disabled:opacity-50 disabled:hover:bg-skin-blue-bg ${
            isSecondary
                ? 'bg-skin-site-secondary-bg font-medium text-[#191919]'
                : 'bg-skin-blue-bg text-white'
        } ${classes}`;

        return !isNextLink ? (
            <button className={buttonClass} {...rest} />
        ) : (
            <a ref={ref} className={buttonClass} {...rest} />
        );
    }
);

Button.displayName = 'Button';

interface ButtonProps {
    type?: 'submit' | 'reset' | 'button';
    onClick?: MouseEventHandler;
    className?: string;
    disabled?: boolean;
    isNextLink?: boolean;
    isSecondary?: boolean;
    children: React.ReactNode;
}
