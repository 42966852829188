import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from '../../store/store';
import { CookiesProvider } from 'react-cookie';
import SessionMonitoring from '../SessionMonitoring';
import SideCart from '../SideCart';
import { useQuery } from '@apollo/client';
import APP_QUERY from '~/queries/App.graphql';
import NextNprogress from 'nextjs-progressbar';
import Header from '../Header/Header';
import Footer from '../Footer';
import NewsletterFooterBlock from '../NewsletterFooterBlock/NewsletterFooterBlock';
import { useRouter } from 'next/router';
import GetCartDetails from './GetCartDetails';
// import RenderFacebookPixel from './RenderFacebookPixel';
import CookiesSetState from '../CookiesSetState';
import { isIOS } from 'react-device-detect';
interface AppProps {
    children: JSX.Element;
}

export const App = ({ children }: AppProps) => {
    const { data, loading } = useQuery(APP_QUERY);
    const router = useRouter();

    const storeConfig = data?.storeConfig;

    const categoryUrlSuffix = '';

    const categories = data?.categoryList[0].children;

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <React.Fragment>
                    <CookiesProvider>
                        <div className={`${isIOS ? 'ios' : ''}`}>
                            <SideCart />
                            <div className="bg-skin-site-bg min-h-screen flex flex-col justify-between ">
                                <NextNprogress
                                    startPosition={0.4}
                                    stopDelayMs={200}
                                    height={3}
                                    options={{
                                        showSpinner: false,
                                        easing: 'ease'
                                    }}
                                />
                                <Header
                                    categories={categories}
                                    loadingNavi={loading}
                                    categoryUrlSuffix={categoryUrlSuffix}
                                    storeConfig={storeConfig}
                                />

                                <div className=" flex-1 flex items-stretch flex-col">
                                    {children}
                                </div>

                                {!router.pathname.includes('account') &&
                                !router.asPath.includes(
                                    '/newsletter-funkelhaus'
                                ) ? (
                                    <NewsletterFooterBlock />
                                ) : null}
                                <Footer />
                            </div>
                            <SessionMonitoring />
                            <GetCartDetails />
                            <CookiesSetState />
                        </div>
                    </CookiesProvider>
                    {/* <RenderFacebookPixel router={router} /> */}
                </React.Fragment>
            </PersistGate>
        </Provider>
    );
};
