import React, { useEffect } from 'react';

interface OverlayProps {
    children?: JSX.Element;
    onClick: Function;
    isFullHeight?: boolean;
    mobileBottomMode?: boolean;
}

const Overlay = (props: OverlayProps) => {
    const { children, onClick, isFullHeight, mobileBottomMode } = props;

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'visible';
        };
    });

    return (
        <div
            className={`fixed top-0 left-0 flex justify-center ${
                mobileBottomMode ? 'items-end md:items-center' : 'items-center'
            } h-full w-full bg-black bg-opacity-25 z-40`}
            onClick={() => onClick(false)}
        >
            <div
                className={`${!mobileBottomMode ? 'mx-3' : ''} w-full ${
                    isFullHeight ? 'h-[100svh] md:h-auto' : ''
                }`}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default Overlay;
