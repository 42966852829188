import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import app from './app';
import cart from './cart';
import user from './user';
import wishlist from './wishlist';
import pickupAndDelivery from './pickupAndDelivery';
import availableShippingMethods from './availableShippingMethods';

const createNoopStorage = () => {
    return {
        getItem(_key) {
            return Promise.resolve(null);
        },
        setItem(_key, value) {
            return Promise.resolve(value);
        },
        removeItem(_key) {
            return Promise.resolve();
        }
    };
};

const storage =
    typeof window !== 'undefined'
        ? createWebStorage('local')
        : createNoopStorage();

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
    blacklist: ['app']
};

const rootReducer = combineReducers({
    cart,
    app,
    user,
    wishlist,
    pickupAndDelivery,
    availableShippingMethods
});

export default persistReducer(persistConfig, rootReducer);
