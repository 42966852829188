export const setUserDetails = (payload) => {
    return {
        type: 'SET_USER_DETAILS',
        payload
    };
};

export const setLoadingUserDetails = (payload) => {
    return {
        type: 'LOADING_USER_DETAILS',
        payload
    };
};

export const setErrorUserDetails = (payload) => {
    return {
        type: 'ERROR_USER_DETAILS',
        payload
    };
};

export const signOut = (payload) => {
    return {
        type: 'SIGN_OUT',
        payload
    };
};

export const setLoadingSignOut = (payload) => {
    return {
        type: 'SET_LOADING_SIGN_OUT',
        payload
    };
};

export const resetUser = () => {
    return {
        type: 'RESET_USER'
    };
};
