import { useQuery } from '@apollo/client';
import CMS_BLOCK from '../../queries/cmsBlock.graphql';
import RichContent from '../RichContent/RichContent';

interface CmsBlockTypes {
    identifiers: string[];
}

const CmsBlock = ({ identifiers }: CmsBlockTypes) => {
    const { data, loading, error } = useQuery(CMS_BLOCK, {
        variables: {
            identifiers
        }
    });

    if (loading) {
        return <span />;
    }

    if (error) {
        return <span />;
    }

    const cmsBlocksData = data?.cmsBlocks?.items;
    return (
        <div>
            {Array.isArray(cmsBlocksData) ? (
                cmsBlocksData.map((item) => {
                    return (
                        <div
                            key={item.identifier}
                            id={'cms_block_' + item.identifier}
                        >
                            <RichContent
                                key={item.identifier}
                                html={item?.content}
                            />
                        </div>
                    );
                })
            ) : (
                <span />
            )}
        </div>
    );
};
export default CmsBlock;
