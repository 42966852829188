import { useMutation, useApolloClient } from '@apollo/client';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import {
    signOut,
    resetUser,
    setSign,
    setLoadingSignOut,
    setDataSignOut
} from '../../store/actions/user';
import { useCookies } from 'react-cookie';
import { setWishlistMode, resetWishlist } from '../../store/actions/wishlist';
import { resetCartState } from '../../store/actions/cart';
import { useEffect } from 'react';

export const useSignOut = (props) => {
    const { signOutMutation: SIGN_OUT } = props;
    const dispatch = useDispatch();
    const [, , removeCookie] = useCookies(['authToken']);

    const handleWishlistMode = (payload) => dispatch(setWishlistMode(payload));

    const router = useRouter();

    const [signOutMutation, { data, loading, called }] = useMutation(SIGN_OUT, {
        onError: (error) => {
            console.log(error);
        }
    });

    useEffect(() => {
        if ((loading || data) && called) {
            dispatch(setLoadingSignOut(true));
        }
    }, [loading, data, dispatch, called]);

    const signOutAction = (payload) => {
        dispatch(signOut(payload));
    };
    const resetUserAction = () => {
        dispatch(resetUser());
    };

    const client = useApolloClient();
    const handledSignOut = async () => {
        await client.stop();
        await client.resetStore();
        await signOutAction({ signOut: signOutMutation });
        await resetUserAction();
        await removeCookie('authToken');
        await handleWishlistMode(true);
        await dispatch(resetWishlist());
        await dispatch(resetCartState());
        await router.push('/login');
        await router.reload();
        await localStorage.removeItem('signin_token');
        dispatch(setLoadingSignOut(false));
    };

    return {
        handledSignOut
    };
};
