import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Price from '../Price';
import Totals from './Totals';
import CheckArrowDark from '../../images/check_arrow_dark.svg';
import { toggleDrawer } from '../../store/actions/app';
import { connect } from 'react-redux';
import Image from 'next/image';
//import ProductCount from './ProductCount';
import ProductUpdate from './ProductUpdate';
import { useCart } from '../../lib/useCart';
import ErrorMessage from '../ErrorHandler/ErrorMessage';
import DeliveryTime from '../DeliveryTimeApi/deliveryTime';
import Link from 'next/link';
import CloseIcon from '../../images/close.svg';
import Button from '../../components/Button';
import PopupBox from '../PopupBox';
import RenderMessages from '../RenderMessages';
import { createCartState } from '../../store/actions/cart';

type productItemProp = {
    id: string;
    product: {
        mhs_stock: string;
        id: string;
        product_state?: string;
        name: string;
        sku: string;
        url_key: string;
        delivery_type: string;
        delivery_time: number;
        attribute_set_id: string;
        small_image: {
            url: string;
            label: string;
        };
        price_range: {
            minimum_price: {
                final_price: {
                    currency: string;
                    value: string;
                };
                discount: {
                    amount_off: string;
                    percent_off: string;
                };
                regular_price: {
                    currency: string;
                    value: string;
                };
            };
            maximum_price: {
                final_price: {
                    currency: string;
                    value: string;
                };
                discount: {
                    amount_off: string;
                };
                regular_price: {
                    currency: string;
                    value: string;
                };
            };
        };
    };
    quantity: string;
};

type CartType = {
    cartId: string;
    addItemError?: string;
    updateItemError: {
        sku: string;
        message: string;
    }[];
    details: {
        items: [productItemProp];
        applied_coupons: {
            code: string;
        }[];
        prices: {
            grand_total: {
                value: string;
                currency: string;
            };
            applied_taxes: {
                amount: {
                    value: string;
                    currency: string;
                };
                label: string;
            };

            discounts: {
                amount: {
                    value: string;
                    currency: string;
                };
                label: string;
            };
            subtotal_including_tax: {
                value: string;
                currency: string;
            };
            subtotal_excluding_tax: {
                value: string;
                currency: string;
            };
        };
    };
};

interface SideCartProps {
    drawer?: string;
    cart: CartType;
    toggleCart: Function;
    isSignedIn: boolean;
    addingItemInCartErrorReset: Function;
}

const SideCart = (props: SideCartProps) => {
    const { cart, drawer, toggleCart, isSignedIn, addingItemInCartErrorReset } =
        props;
    const isOpen = drawer == 'cart';
    const [isBoxOpened, setIsBoxOpened] = useState(true);
    let regularPricesSum = 0;
    let amountOffSum = 0;
    cart?.details?.items.map((item: productItemProp) => {
        let quantity = item.quantity;
        const amountoff =
            item.product.price_range.maximum_price.discount.amount_off;
        const regularprice =
            item.product.price_range.maximum_price.regular_price.value;

        regularPricesSum += +quantity * +regularprice;
        amountOffSum += +quantity * +amountoff;
    });
    const {
        cartDetailsLoading,
        addToCartLoading,
        updateCartLoading,
        removeFromCartLoading,
        isLoading
    } = useCart({});

    useEffect(() => {
        cart?.addItemError ? setIsBoxOpened(true) : setIsBoxOpened(false);
    }, [cart.addItemError]); //eslint-disable-line

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 overflow-hidden z-30"
                onClose={() => {
                    toggleCart('cart');
                }}
            >
                <div className="absolute inset-0 overflow-hidden">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-y-0 right-0 flex w-full sm:max-w-xl">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="w-full relative">
                                {cartDetailsLoading ||
                                addToCartLoading ||
                                updateCartLoading ||
                                removeFromCartLoading ||
                                isLoading ? (
                                    <div className="block w-full h-full absolute top-0 left-0 bg-white opacity-50 z-10" />
                                ) : null}
                                <div
                                    className={
                                        cart?.details?.items?.length > 0
                                            ? 'h-full flex flex-col bg-white shadow-xl overflow-y-scroll'
                                            : 'h-full flex flex-col bg-white shadow-xl overflow-y-auto'
                                    }
                                >
                                    <div className="flex-1">
                                        <div className="flex items-center justify-between">
                                            <Dialog.Title className="text-lg lg:text-2xl pl-4 font-semibold mb-0">
                                                Warenkorb
                                            </Dialog.Title>
                                            <button
                                                type="button"
                                                className="p-4 text-black"
                                                onClick={() =>
                                                    toggleCart('cart')
                                                }
                                            >
                                                <span className="sr-only">
                                                    Close menu
                                                </span>
                                                <CloseIcon />
                                            </button>
                                        </div>
                                        {cart?.details?.items?.length ? (
                                            <div className="text-base flex justify-center items-center p-2 bg-skin-soft-blue-bg">
                                                <CheckArrowDark className="w-4 h-4 mr-2 lg:w-6 lg:h-6 lg:mr-4" />
                                                Zum Warenkorb hinzugefügt
                                            </div>
                                        ) : null}

                                        <div className="mt-8 flex flex-col flex-1 cartContainer">
                                            <div className="flow-root flex-1">
                                                {cart?.details?.items
                                                    ?.length ? (
                                                    <ul
                                                        role="list"
                                                        className="-my-6 divide-y divide-gray-200"
                                                    >
                                                        {cart.details.items.map(
                                                            (item) => (
                                                                <li
                                                                    key={
                                                                        item
                                                                            .product
                                                                            .id
                                                                    }
                                                                    className="relative py-6 border-solid border-skin-base"
                                                                >
                                                                    <div className="flex flex-col px-4 w-full sideCartProductXS">
                                                                        <div className="mb-2">
                                                                            <div className="flex-shrink-0 w-32 h-32 m-auto border border-gray-200 rounded-md overflow-hidden">
                                                                                <Link
                                                                                    passHref
                                                                                    href={`/${item.product.url_key}.html`}
                                                                                >
                                                                                    <a
                                                                                        onClick={() =>
                                                                                            toggleCart()
                                                                                        }
                                                                                    >
                                                                                        <Image
                                                                                            width="118"
                                                                                            height="118"
                                                                                            src={
                                                                                                item
                                                                                                    .product
                                                                                                    .small_image
                                                                                                    .url
                                                                                            }
                                                                                            alt={
                                                                                                item
                                                                                                    .product
                                                                                                    .small_image
                                                                                                    .label
                                                                                            }
                                                                                            className="w-full h-full object-center object-contain aspect-square"
                                                                                        />
                                                                                    </a>
                                                                                </Link>
                                                                            </div>

                                                                            <div className="flex-1 flex flex-col justify-between">
                                                                                <div>
                                                                                    <div className="flex justify-between flex-col text-base font-medium text-skin-base">
                                                                                        <Link
                                                                                            passHref
                                                                                            href={`/${item.product.url_key}.html`}
                                                                                        >
                                                                                            <a
                                                                                                onClick={() =>
                                                                                                    toggleCart()
                                                                                                }
                                                                                            >
                                                                                                <span className="text-base font-semibold">
                                                                                                    {
                                                                                                        item
                                                                                                            .product
                                                                                                            .name
                                                                                                    }
                                                                                                </span>
                                                                                            </a>
                                                                                        </Link>
                                                                                        <DeliveryTime
                                                                                            sku={
                                                                                                item
                                                                                                    .product
                                                                                                    .sku
                                                                                            }
                                                                                            deliveryType={Number(
                                                                                                item
                                                                                                    .product
                                                                                                    .delivery_type
                                                                                            )}
                                                                                            deliveryTime={
                                                                                                item
                                                                                                    .product
                                                                                                    .delivery_time
                                                                                            }
                                                                                            selectedQuantity={Number(
                                                                                                item?.quantity
                                                                                            )}
                                                                                            rootClass={
                                                                                                'text-sm'
                                                                                            }
                                                                                            mhsStock={
                                                                                                item
                                                                                                    ?.product
                                                                                                    ?.mhs_stock
                                                                                            }
                                                                                            productState={
                                                                                                item
                                                                                                    ?.product
                                                                                                    ?.product_state
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex justify-start text-base font-medium text-skin-muted">
                                                                            <ProductUpdate
                                                                                sku={
                                                                                    item
                                                                                        .product
                                                                                        .sku
                                                                                }
                                                                                productId={
                                                                                    item.id
                                                                                }
                                                                                initialQuantity={
                                                                                    item?.quantity
                                                                                }
                                                                            />
                                                                            <Price
                                                                                priceRange={
                                                                                    item
                                                                                        ?.product
                                                                                        ?.price_range
                                                                                }
                                                                                quantity={
                                                                                    item?.quantity
                                                                                }
                                                                                discountClass="text-xs lg:text-sm text-skin-muted flex font-semibold lg:mx-3 line-through decoration-[#e3001b]"
                                                                                priceClass="flex flex-col items-start ml-6 lg:flex-row-reverse lg:items-center text-2xl text-skin-muted text-skin-secondary font-bold"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="hidden px-4 w-full sideCartProductSM">
                                                                        <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
                                                                            <Link
                                                                                passHref
                                                                                href={`/${item.product.url_key}.html`}
                                                                            >
                                                                                <a
                                                                                    onClick={() =>
                                                                                        toggleCart()
                                                                                    }
                                                                                >
                                                                                    <Image
                                                                                        width="118"
                                                                                        height="118"
                                                                                        src={
                                                                                            item
                                                                                                .product
                                                                                                .small_image
                                                                                                .url
                                                                                        }
                                                                                        alt={
                                                                                            item
                                                                                                .product
                                                                                                .small_image
                                                                                                .label
                                                                                        }
                                                                                        className="w-full h-full object-center object-contain aspect-square"
                                                                                    />
                                                                                </a>
                                                                            </Link>
                                                                        </div>

                                                                        <div className="ml-4 flex-1 flex flex-col justify-between">
                                                                            <div className="pr-6">
                                                                                <div className="flex justify-between flex-col text-base font-medium text-skin-base">
                                                                                    <Link
                                                                                        passHref
                                                                                        href={`/${item.product.url_key}.html`}
                                                                                    >
                                                                                        <a
                                                                                            onClick={() =>
                                                                                                toggleCart()
                                                                                            }
                                                                                        >
                                                                                            <span className="text-base  font-semibold">
                                                                                                {
                                                                                                    item
                                                                                                        .product
                                                                                                        .name
                                                                                                }
                                                                                            </span>
                                                                                        </a>
                                                                                    </Link>
                                                                                    <span className="text-sm">
                                                                                        <DeliveryTime
                                                                                            sku={
                                                                                                item
                                                                                                    .product
                                                                                                    .sku
                                                                                            }
                                                                                            deliveryType={Number(
                                                                                                item
                                                                                                    .product
                                                                                                    .delivery_type
                                                                                            )}
                                                                                            deliveryTime={
                                                                                                item
                                                                                                    .product
                                                                                                    .delivery_time
                                                                                            }
                                                                                            selectedQuantity={Number(
                                                                                                item?.quantity
                                                                                            )}
                                                                                            rootClass={
                                                                                                'text-sm'
                                                                                            }
                                                                                            mhsStock={
                                                                                                item
                                                                                                    ?.product
                                                                                                    ?.mhs_stock
                                                                                            }
                                                                                            productState={
                                                                                                item
                                                                                                    ?.product
                                                                                                    ?.product_state
                                                                                            }
                                                                                        />
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex justify-start text-base font-medium text-skin-muted">
                                                                                <ProductUpdate
                                                                                    sku={
                                                                                        item
                                                                                            .product
                                                                                            .sku
                                                                                    }
                                                                                    productId={
                                                                                        item.id
                                                                                    }
                                                                                    initialQuantity={
                                                                                        item?.quantity
                                                                                    }
                                                                                />
                                                                                <Price
                                                                                    priceRange={
                                                                                        item
                                                                                            ?.product
                                                                                            ?.price_range
                                                                                    }
                                                                                    quantity={
                                                                                        item?.quantity
                                                                                    }
                                                                                    discountClass="text-xs lg:text-sm text-skin-muted flex font-semibold lg:mx-3 line-through decoration-[#e3001b]"
                                                                                    priceClass="flex flex-col items-start ml-6 lg:flex-row-reverse lg:items-center text-2xl text-skin-muted text-skin-secondary font-bold"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {cart?.updateItemError &&
                                                                    cart?.updateItemError.filter(
                                                                        (f) =>
                                                                            f.sku ==
                                                                            item
                                                                                .product
                                                                                .sku
                                                                    ).length >
                                                                        0 ? (
                                                                        <div className="px-4">
                                                                            <ErrorMessage
                                                                                message={
                                                                                    cart?.updateItemError.filter(
                                                                                        (
                                                                                            f
                                                                                        ) =>
                                                                                            f.sku ==
                                                                                            item
                                                                                                .product
                                                                                                .sku
                                                                                    )[0]
                                                                                        .message
                                                                                }
                                                                                icon={
                                                                                    true
                                                                                }
                                                                            />
                                                                        </div>
                                                                    ) : null}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                ) : (
                                                    <div className="flex w-full h-full items-center px-4 py-2  text-skin-muted z-10">
                                                        <div className="flex-1 text-sm text-skin-muted text-center items-center mb-20">
                                                            {/* Es befinden sich
                                                            keine Artikel in
                                                            deinem Warenkorb. */}
                                                            <div className="text-center">
                                                                <h2>
                                                                    Dein
                                                                    Warenkorb
                                                                    ist leer.
                                                                </h2>
                                                                <p className="text-center">
                                                                    Dir ist noch
                                                                    kein Licht
                                                                    aufgegangen?
                                                                    Stöbere dich
                                                                    jetzt durch
                                                                    unsere große
                                                                    Auswahl und
                                                                    fülle deinen
                                                                    Warenkorb
                                                                    mit den
                                                                    neusten
                                                                    Trendleuchten
                                                                    für dein
                                                                    Zuhause,{' '}
                                                                    <Link
                                                                        href="/"
                                                                        passHref
                                                                    >
                                                                        <a
                                                                            onClick={() =>
                                                                                toggleCart(
                                                                                    'cart'
                                                                                )
                                                                            }
                                                                        >
                                                                            oder
                                                                            melde
                                                                            dich
                                                                            in
                                                                            deinem
                                                                            Kundenkonto
                                                                            an
                                                                        </a>
                                                                    </Link>
                                                                    , um
                                                                    Produkte zu
                                                                    sehen, die
                                                                    du bereits
                                                                    hinzugefügt
                                                                    hast.
                                                                </p>
                                                                <div className="flex justify-center items-center flex-col">
                                                                    {!isSignedIn ? (
                                                                        <Link
                                                                            href="/login"
                                                                            passHref
                                                                        >
                                                                            <Button
                                                                                onClick={() =>
                                                                                    toggleCart(
                                                                                        'cart'
                                                                                    )
                                                                                }
                                                                                isNextLink={
                                                                                    true
                                                                                }
                                                                                className="w-1/2 mb-2 px-6 text-base"
                                                                            >
                                                                                Anmelden
                                                                            </Button>
                                                                        </Link>
                                                                    ) : (
                                                                        <Link
                                                                            href="/wishlist"
                                                                            passHref
                                                                        >
                                                                            <Button
                                                                                onClick={() =>
                                                                                    toggleCart(
                                                                                        'cart'
                                                                                    )
                                                                                }
                                                                                isNextLink={
                                                                                    true
                                                                                }
                                                                                className="w-1/2 mb-2 px-6 text-base"
                                                                            >
                                                                                Wunschliste
                                                                            </Button>
                                                                        </Link>
                                                                    )}
                                                                    <Button
                                                                        className="w-1/2 mb-4 lg:mb-0 px-6 text-base"
                                                                        onClick={() =>
                                                                            toggleCart(
                                                                                'cart'
                                                                            )
                                                                        }
                                                                        isSecondary
                                                                    >
                                                                        Weiter
                                                                        einkaufen
                                                                    </Button>
                                                                </div>
                                                                <Link
                                                                    href="/register"
                                                                    passHref
                                                                >
                                                                    <a
                                                                        className="block mt-2 underline"
                                                                        onClick={() =>
                                                                            toggleCart(
                                                                                'cart'
                                                                            )
                                                                        }
                                                                    >
                                                                        Neuer
                                                                        Kunde?
                                                                        Jetzt
                                                                        registrieren
                                                                    </a>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {cart?.details?.items.length > 0 ? (
                                        <Totals
                                            prices={cart.details.prices}
                                            cartId={cart.cartId}
                                            toggleCart={toggleCart}
                                            regularPricesSum={regularPricesSum}
                                            amountOffSum={amountOffSum}
                                            coupons={
                                                cart.details.applied_coupons
                                            }
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
                {cart.addItemError ? (
                    <PopupBox
                        buttons={[
                            {
                                label: 'Zurück',
                                action: () => {
                                    setIsBoxOpened;
                                    addingItemInCartErrorReset(cart);
                                },
                                disabledStyles: true
                            }
                        ]}
                        headline={'Ein Fehler ist aufgetreten!'}
                        isBoxOpened={isBoxOpened}
                        setIsBoxOpened={setIsBoxOpened}
                        closeByOverlayDisabled={true}
                    >
                        <RenderMessages
                            messageStatus={'warning'}
                            message={
                                cart?.addItemError.includes(
                                    'exceeds the maximum qty'
                                )
                                    ? 'Die gewählte Menge überschreitet die mögliche Menge im Einkaufswagen.'
                                    : cart?.addItemError
                            }
                            isBox={true}
                        />
                    </PopupBox>
                ) : null}
            </Dialog>
        </Transition.Root>
    );
};

const mapStateToProps = (state: {
    cart: CartType;
    app: { drawer: string };
    user: { isSignedIn: boolean };
}) => {
    const {
        cart,
        app: { drawer },
        user: { isSignedIn }
    } = state;
    return {
        cart: cart,
        drawer,
        isSignedIn
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        toggleCart: (payload: string) => dispatch(toggleDrawer(payload)),
        addingItemInCartErrorReset: (cart: CartType) => {
            dispatch(
                createCartState({
                    ...cart,
                    addItemError: null
                })
            );
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideCart);
