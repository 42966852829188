const Variants = (props: {
    isRelatedColor: boolean;
    isRelatedSize: boolean;
}) => {
    const { isRelatedColor, isRelatedSize } = props;
    return isRelatedColor || isRelatedSize ? (
        <div className="flex flex-col">
            <span className="text-skin-muted text-xs mt-1">
                weitere Varianten:
            </span>
            <div className="flex justify-start mt-1">
                {isRelatedColor ? (
                    <svg
                        id="color-circle"
                        xmlns="http://www.w3.org/2000/svg"
                        width="22.587"
                        height="22.587"
                        viewBox="0 0 22.587 22.587"
                        className="mr-1"
                    >
                        <path
                            id="Path_1052"
                            data-name="Path 1052"
                            d="M82.985,4.65,83.426,2.6,82.985,0A11.2,11.2,0,0,0,75,3.309l.662,1.548L78.287,6.6A6.636,6.636,0,0,1,82.985,4.65Z"
                            transform="translate(-71.691 0)"
                            fill="#ff5e90"
                        />
                        <path
                            id="Path_1053"
                            data-name="Path 1053"
                            d="M6.6,78.287,3.309,75A11.2,11.2,0,0,0,0,82.985l2.1.441,2.554-.441a6.636,6.636,0,0,1,1.945-4.7Z"
                            transform="translate(0 -71.691)"
                            fill="#ffec29"
                        />
                        <path
                            id="Path_1054"
                            data-name="Path 1054"
                            d="M260.7,6.6l1.945-1.5,1.341-1.782A11.2,11.2,0,0,0,256,0V4.65A6.636,6.636,0,0,1,260.7,6.6Z"
                            transform="translate(-244.706 0)"
                            fill="#ec3d93"
                        />
                        <path
                            id="Path_1055"
                            data-name="Path 1055"
                            d="M4.65,256H0a11.2,11.2,0,0,0,3.309,7.985l1.769-1.328L6.6,260.7A6.636,6.636,0,0,1,4.65,256Z"
                            transform="translate(0 -244.707)"
                            fill="#ffd321"
                        />
                        <path
                            id="Path_1056"
                            data-name="Path 1056"
                            d="M78.287,362.5,75,365.787a11.212,11.212,0,0,0,7.985,3.309l.441-2.656-.441-1.994a6.636,6.636,0,0,1-4.7-1.945Z"
                            transform="translate(-71.691 -346.508)"
                            fill="#27d072"
                        />
                        <path
                            id="Path_1057"
                            data-name="Path 1057"
                            d="M364.445,82.985l2.4.441,2.246-.441A11.2,11.2,0,0,0,365.787,75L362.5,78.287A6.636,6.636,0,0,1,364.445,82.985Z"
                            transform="translate(-346.508 -71.691)"
                            fill="#4793ff"
                        />
                        <path
                            id="Path_1058"
                            data-name="Path 1058"
                            d="M364.445,256a6.636,6.636,0,0,1-1.945,4.7l1,1.888,2.285,1.4A11.212,11.212,0,0,0,369.1,256Z"
                            transform="translate(-346.508 -244.707)"
                            fill="#056eff"
                        />
                        <path
                            id="Path_1059"
                            data-name="Path 1059"
                            d="M256,364.445v4.65a11.2,11.2,0,0,0,7.985-3.309L260.7,362.5A6.636,6.636,0,0,1,256,364.445Z"
                            transform="translate(-244.706 -346.508)"
                            fill="#1ea682"
                        />
                    </svg>
                ) : null}
                {isRelatedSize ? (
                    <svg
                        id="size"
                        xmlns="http://www.w3.org/2000/svg"
                        width="23.914"
                        height="23.915"
                        viewBox="0 0 23.914 23.915"
                    >
                        <path
                            id="Path_1063"
                            data-name="Path 1063"
                            d="M4.484,2.989a.5.5,0,0,1-.353-.145L2.491,1.2.851,2.844a.5.5,0,0,1-.7-.7L2.139.146a.5.5,0,0,1,.7,0L4.837,2.139a.5.5,0,0,1-.353.85Z"
                            transform="translate(-0.001 -0.001)"
                            fill="#9b9e9f"
                        />
                        <path
                            id="Path_1064"
                            data-name="Path 1064"
                            d="M21.5,23.982a.5.5,0,0,1-.353-.85l1.64-1.64-1.64-1.64a.5.5,0,0,1,.7-.7l1.993,1.993a.5.5,0,0,1,0,.7L21.85,23.838A.5.5,0,0,1,21.5,23.982Z"
                            transform="translate(-0.074 -0.068)"
                            fill="#9b9e9f"
                        />
                        <path
                            id="Path_1065"
                            data-name="Path 1065"
                            d="M2.5,21.923a.479.479,0,0,1-.5-.456V.706A.479.479,0,0,1,2.5.25.479.479,0,0,1,3,.706V21.467A.479.479,0,0,1,2.5,21.923Z"
                            transform="translate(-0.008 -0.002)"
                            fill="#9b9e9f"
                        />
                        <path
                            id="Path_1066"
                            data-name="Path 1066"
                            d="M21.217,22H.456a.5.5,0,0,1,0-1H21.217a.5.5,0,0,1,0,1Z"
                            transform="translate(1.992 -0.075)"
                            fill="#9b9e9f"
                        />
                        <path
                            id="Path_1067"
                            data-name="Path 1067"
                            d="M12.975,9.979a.506.506,0,0,1-.179-.033L6.319,7.455a.5.5,0,0,1,0-.931L12.8,4.033a.5.5,0,0,1,.358,0l6.477,2.491a.5.5,0,0,1,0,.931L13.155,9.946A.506.506,0,0,1,12.975,9.979ZM7.886,6.989l5.089,1.957,5.089-1.957L12.975,5.032Z"
                            transform="translate(-0.022 -0.015)"
                            fill="#9b9e9f"
                        />
                        <path
                            id="Path_1068"
                            data-name="Path 1068"
                            d="M12.975,17.96a.506.506,0,0,1-.179-.033L6.319,15.435A.5.5,0,0,1,6,14.97V7A.5.5,0,0,1,7,7v7.63l5.979,2.3,5.979-2.3V7a.5.5,0,0,1,1,0V14.97a.5.5,0,0,1-.319.465l-6.477,2.491A.506.506,0,0,1,12.975,17.96Z"
                            transform="translate(-0.022 -0.024)"
                            fill="#9b9e9f"
                        />
                        <path
                            id="Path_1069"
                            data-name="Path 1069"
                            d="M13,17.968a.5.5,0,0,1-.5-.5V9.5a.5.5,0,0,1,1,0V17.47A.5.5,0,0,1,13,17.968Z"
                            transform="translate(-0.045 -0.032)"
                            fill="#9b9e9f"
                        />
                    </svg>
                ) : null}
            </div>
        </div>
    ) : null;
};

export default Variants;
