import React, { useState, useEffect } from 'react';
import NewsletterBlockImage from '../../images/newsletter_block_image.png';
import Image from 'next/image';
import CmsBlock from '../CmsBlock/cmsBlock';
import Checkbox from '../Checkbox';

const NewsletterFooterBlock = () => {
    const newsletterBlockImage = (
        <Image src={NewsletterBlockImage} alt="5 EURO Gutschein*" />
    );

    const [policiesAreAccepted, setPoliciesAreAccepted] = useState(false);

    useEffect(() => {
        const signInElm = document.getElementById(
            'signIn'
        ) as HTMLButtonElement;

        const signInElmMobile = document.getElementById(
            'signInMobile'
        ) as HTMLButtonElement;

        if (signInElm && signInElmMobile) {
            if (!policiesAreAccepted) {
                signInElm.disabled = true;
                signInElmMobile.disabled = true;
            } else {
                signInElm.disabled = false;
                signInElmMobile.disabled = false;
            }
        }
    }, [policiesAreAccepted]); // eslint-disable-line

    return (
        <div className="flex justify-between items-center flex-col px-4 py-6 mt-4 mb-8 sm:px-0 xl:py-4 xl:flex-row bg-[#F3F3F3]">
            {/* Desktop */}
            <div className="content-container flex justify-around items-center flex-col xl:flex-row">
                {/* <NewsletterBlockImage
                    width={350}
                    height={200}
                    alt="5 EURO Gutschein*"
                /> */}
                <div className="w-full p-2 mb-2 bg-[#E3001B] text-center text-2xl text-white rounded-md whitespace-nowrap sm:text-4xl block xl:hidden">
                    5 EURO Gutschein*
                </div>
                <div className="hidden items-center justify-center w-96 xl:flex">
                    {newsletterBlockImage}
                </div>
                <div className="w-full xl:w-1/4 text-center">
                    <div className="font-medium text-[18px]">
                        Jetzt Funkelhaus Newsletter abonnieren, und keine
                        Neuigkeiten mehr verpassen.
                    </div>
                    <div className="text-xs mb-2">
                        *Mindestbestellwert 100€. Abmeldung jederzeit möglich.
                    </div>
                </div>
                <div className="w-full xl:w-2/5">
                    <CmsBlock
                        identifiers={['newsletter_cleaverreach_block ']}
                    />
                    <Checkbox
                        field="registerFormVisibility"
                        label={
                            "Ja, ich möchte per Newsletter über Trends, Aktionen und Gutscheine informiert werden. Mehr zum Datenschutz <a href='/datenschutz'>hier</a>."
                        }
                        id="registerFormVisibility"
                        onClick={() =>
                            setPoliciesAreAccepted(!policiesAreAccepted)
                        }
                        skipParse={true}
                        className={'flex'}
                    />
                </div>
            </div>
        </div>
    );
};

export default NewsletterFooterBlock;
