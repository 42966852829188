import { connect } from 'react-redux';

interface ProductCountPropTypes {
    cart?: {
        details: {
            items: { quantity: string }[];
        };
    };
    mobileView?: boolean;
}

const ProductCount = (props: ProductCountPropTypes) => {
    const { cart, mobileView } = props;

    let count: number = 0;
    if (cart?.details?.items) {
        {
            cart.details.items.map(
                (item) => (count = Number(count + item.quantity))
            );
        }
    }
    // <span className="minicart-counter">{count}</span>
    return count && typeof count === 'number' && count > 0 ? (
        <span className={mobileView ? 'counter' : 'counter-desktop'}>
            {count} {!mobileView ? 'Artikel' : ''}
        </span>
    ) : !mobileView ? (
        <span className={mobileView ? 'counter' : ''}>{'0 Artikel'}</span>
    ) : null;
};

const mapStateToProps = ({ cart }: { cart: object }) => {
    return {
        cart: cart
    };
};

export default connect(mapStateToProps, null)(ProductCount);
